import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";

class EconomicGraph extends PureComponent {
  constructor() {
    super();
    this.getGraphValues = this.getGraphValues.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getGraphValues(graphValue) {
    const graphValues = (graphValue || []).map((item) => item.value);

    const data = {
      labels: graphValues,
      datasets: [
        {
          fill: true,
          lineTension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#4599cd87",
          borderCapStyle: "butt",
          pointBorderColor: "red",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#4599cd87",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 10,
          data: graphValues,
        },
      ],
    };
    return data;
  }


  drawGraph = (graphValue) => {
    const graphValues = (graphValue || []).map((item) => item.value);

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 170);
      gradient.addColorStop(0, "#bdbbfb");
      gradient.addColorStop(1, "#807df8");

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 170);
      gradientFill.addColorStop(0, "#e6e8fa");
      gradientFill.addColorStop(1, "#ffffff");

      return {
        labels: graphValues,
        datasets: [
          {
            label: "Year",
            fill: true,
            lineTension: 0.3,
            backgroundColor: gradientFill,
            borderColor: gradient,
            borderWidth: 2,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: gradient,
            pointBackgroundColor: gradient,
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: gradient,
            pointHoverBorderColor: gradient,
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointStyle: "circle",
            pointHitRadius: 10,
            data: graphValues,
          },
        ],
      };
    };
    const options = {
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#000",
        bodyFontColor: "#000",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      //lineTension: 5,
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "rgba(0,0,0,0.5)",
              fontStyle: "normal",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
              display: false,
            },
            gridLines: {
              drawTicks: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "rgba(0,0,0,0.5)",
              fontStyle: "normal",
              //fontFamily: 'Montserrat',
              display: false,
            },
          },
        ],
      },
    };
    return <Line data={data} options={options} height="70px" width="110px" responsiveness={true} />;
  };


  render() {
    const { graphData } = this.props;
    return (
      // <div className="indice-graph">
      //   <Line data={this.getGraphValues(graphData)} options={areaOptions} height={70} width={120} />
      // </div>

      <div className="indice-graph">{this.drawGraph(graphData)}</div>
    );
  }
}

export default EconomicGraph;
