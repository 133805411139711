import Api from "./ApiWrapper";
import { RESOURCES_ENDPOINT } from "./ApiEndpoints";

function fetchRegulations(
  country,
  fromDate,
  toDate,
  searchterm,
  page,
  page_size
) {
  const queryParams = `?country=${country || ""}&regulation_date_after=${
    fromDate || ""
  }&regulation_date_before=${toDate || ""}&nameofregulation=${
    searchterm || ""
  }&page=${page}&page_size=${page_size}&pagination=${"yes"}`;
  return Api.get(`${RESOURCES_ENDPOINT}/${queryParams}`);
}

const ResourcesApi = { fetchRegulations };

export default ResourcesApi;
