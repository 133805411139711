import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";
import { get as lodashGet } from "lodash";
// import { Dropdown } from "react-bootstrap";
import PubSub from "pubsub-js";
// import { FILTER_DATE } from '../../../constants/pubsub';
// import { DATE_FORMAT } from '../../../constants/global';
// import DatePicker from "react-datepicker";
import scrollIntoView from 'scroll-into-view-if-needed'
import { DateRangePicker } from "react-date-range";

import {
  addYears,
  startOfMonth,
  endOfMonth,
  // addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfYesterday,
  startOfTomorrow,
  startOfToday,
  endOfYesterday,
  startOfQuarter,
  endOfQuarter,
  format,
} from "date-fns";

// const DATE_FORMAT = "yyyy-MM-dd";
const FILTER_DATE = "fromDate";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  today: startOfToday(new Date()),
  eyesterday:endOfYesterday(),
  yesterday: startOfYesterday(),
  startOfTomorrow:startOfTomorrow(),
  last7days: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  last15days: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000),
  last30days: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
};

class DateFilter extends PureComponent {
  constructor(props) {
    super(props);
    var startminDate=defineds.startOfTomorrow;
    var endmaxDate= addYears(defineds.startOfTomorrow,2);
    var getDaysArray = function(startminDate,endmaxDate) {for(var a=[],d=new Date(startminDate);d<=endmaxDate;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};//for creating array for disable dates
    this.state = {
      showDateSelectPanel: false,
      formData:[],
      temp:0,
    };
  }

  handleSelect = (date) => {
    // this.setState({ formData: [date.selection] });
    PubSub.publish(FILTER_DATE, {
      startDate: date.selection.startDate,
      endDate: date.selection.endDate,
    });

    this.setState({
      showDate:
        format(date.selection.startDate, "dd MMM yyyy") +
        " - " +
        format(date.selection.endDate, "dd MMM yyyy"),
    });

    // if (date.selection.startDate !== date.selection.endDate) {
    //   this.setState({ toggle: !this.state.toggle });
    // }
  };

  toggleDateSelectPanel = (state) => {
    const { showDateSelectPanel } = this.state;
    let newState = !showDateSelectPanel;

    if (state === true || state === false) {
      newState = state;
    }

    this.setState({ showDateSelectPanel: newState ,temp:0 });
    if(this.props.special && newState){
      // setTimeout(() => {
      //   document.getElementById("in-focus").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
       

      // }, 10);
    const node =document.getElementById("in-focus");
    setTimeout(() => {
    scrollIntoView(node, { behavior: 'smooth',block: 'start', scrollMode: 'always' });
  }, 10);
      }
  };

  handleOnChange = (range) => {
    let { onChange } = this.props;
    this.setState({ formData: range,temp:this.state.temp+1 });
    var endDate = lodashGet(range, "selection.endDate", null);
    var startDate = lodashGet(range, "selection.startDate", null);
   
    if (!(onChange instanceof Function)) {
      onChange = () => {};
    }
    
    if (endDate.getTime() !== startDate.getTime()) {        // This check the time of start date and endDate
      this.toggleDateSelectPanel(false);
      this.setState({ formData: range,temp:0 },()=>{onChange(this.state.formData,true);});//True is passed when calendar is closed to hit the api
      }else if(this.state.temp===1){  // if time is equal then state value is checked
        this.toggleDateSelectPanel(false);
        this.setState({ formData: range,temp:0 },()=>{onChange(this.state.formData,true);});
      }else
    onChange(range,false);//this is only to show date selected 

  }

  handleOnChangeNews = (range) => {
   
    const ranges = {selection:
      {
        startDate: defineds.last7days,
        endDate:  defineds.today,
        key: "selection",
      },
    }
    let { onChange } = this.props;
    this.setState({ formData: range,temp:this.state.temp+1  });
    const endDate = lodashGet(range, "selection.endDate", null);
    const startDate = lodashGet(range, "selection.startDate", null);

    if (!(onChange instanceof Function)) {
      onChange = () => {};
    }
    if (endDate.getTime() !== startDate.getTime()) {    
      const date1 = startDate;
      const date2 = endDate;
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      if(diffDays>30){
        // alert("only 30 days");
        this.setState({ formData: ranges,temp:0 },()=>{onChange(this.state.formData,false,true);});

      }
      // else if(isFuture(startDate)||isFuture(endDate)){
      //   this.setState({ formData: ranges },()=>{onChange(this.state.formData,false,false,true);});
      // }
      else{
      
      this.toggleDateSelectPanel(false);
      this.setState({ formData: range },()=>{onChange(this.state.formData,true);});//True is passed when calendar is closed to hit the api
      }
    }else if(this.state.temp===1){
      this.toggleDateSelectPanel(false);
      this.setState({ formData: range,temp:0 },()=>{onChange(this.state.formData,true);});
    }else
onChange(range,false);//this is only to show date selected 

  };

  render() {
    const { showDateSelectPanel } = this.state;
    const { className, fullWidth } = this.props;
    let { startDate, endDate, placeholder , special,news } = this.props;

    let visibleDate = placeholder || "Select Date Range";

    if (startDate || endDate) {
      visibleDate = `${
        startDate ? format(startDate, "dd MMM yyyy") : "..."
      } to ${endDate ? format(endDate, "dd MMM yyyy") : "..."}`;
    }
    let defaultStartDate;
    let defaultEndDate;
    if (special) {
      defaultStartDate = defineds.last7days;
      defaultEndDate = defineds.today; // today
    } else {
      defaultStartDate = new Date("2000-01-01");
      defaultEndDate = new Date();
    }
 const ranges = [
      {
        startDate: startDate || defaultStartDate,
        endDate: endDate || defaultEndDate,
        key: "selection",
      },
    ];
    if (!special) {
      return (
        <div  
          className={`date-range-picker${className ? " " + className : ""}${
            fullWidth ? " full-width" : "" 
          }`}
        >
          <button
            type="button"
            className="btn btn-primary btn-filter date-range-picker__toggle-button"
            onClick={this.toggleDateSelectPanel}
          >
            {showDateSelectPanel ? (
              <i className="date-range-picker__toggle-button__icon mdi mdi-close"></i>
            ) : (
              <i className="date-range-picker__toggle-button__icon mdi mdi-calendar-range mr-2"></i>
            )}

            <span className="date-range-picker__toggle-button__text">
              {visibleDate}
            </span>
          </button>
          {showDateSelectPanel ? (
            <div
              className={
                showDateSelectPanel
                  ? "custom-filter-wrap"
                  : "custom-filter-wrap hide-calendar"
              }
              // tabindex="0"
              // onBlur={() => this.toggleDateSelectPanel(false)}
            >
              <Form.Group className="row m-0">
                <DateRangePicker
                  onChange={this.handleOnChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={ranges}
                  direction="horizontal"
                  inputRanges={[]}
                  maxDate={new Date()}
                  //showDateDisplay={true}
                  //  showPreview={formData}
                  //  onShownDateChange={(item) => {console.log('YYYYYY')}}
                  autoclose={true}
                  //  initialFocusedRange={formData}
                  staticRanges={[
                    {
                      label: "Till Date",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date("2000-01-01"),
                        endDate: new Date(),
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                    {
                      label: "YTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfYear,
                        //endDate: defineds.endOfYear,
                        endDate:
                          new Date().getTime() <= defineds.endOfYear.getTime()
                            ? new Date()
                            : defineds.endOfYear,
                      }),
                      isSelected() {
                        return false;
                      },
                    },

                    {
                      label: "QTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfQuarter,
                        //endDate: defineds.endOfQuarter,
                        endDate:
                          new Date().getTime() <=
                          defineds.endOfQuarter.getTime()
                            ? new Date()
                            : defineds.endOfQuarter,
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                    {
                      label: "MTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfMonth,
                        //  endDate: defineds.endOfMonth,
                        endDate:
                          new Date().getTime() <= defineds.endOfMonth.getTime()
                            ? new Date()
                            : defineds.endOfMonth,
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                    {
                      label: "WTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfWeek,
                        //  endDate: defineds.endOfWeek,
                        endDate:
                          new Date().getTime() <= defineds.endOfWeek.getTime()
                            ? new Date()
                            : defineds.endOfWeek,
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                  ]}
                />
              </Form.Group>
            </div>
          ) : null}
        </div>
      );
    } else if(!news) {
      return (
        <div className="section-date-range" id="in-focus">
        <div
          className={`date-range-picker${className ? " " + className : ""}${
            fullWidth ? " full-width" : ""
          }`}
        >
          <button
            type="button"
            className="btn btn-primary btn-filter date-range-picker__toggle-button"
            onClick={this.toggleDateSelectPanel}
          >
            {showDateSelectPanel ? (
              <i className="date-range-picker__toggle-button__icon mdi mdi-close"></i>
            ) : (
              <i className="date-range-picker__toggle-button__icon mdi mdi-calendar-range mr-2"></i>
            )}

            <span className="date-range-picker__toggle-button__text">
              {visibleDate}
            </span>
          </button>
          {showDateSelectPanel ? (
            <div
              className={
                showDateSelectPanel
                  ? "custom-filter-wrap"
                  : "custom-filter-wrap hide-calendar"
              }
              // tabindex="0"
              // onBlur={() => this.toggleDateSelectPanel(false)}
            >
              <Form.Group className="row m-0">
                <DateRangePicker
                  onChange={this.handleOnChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={ranges}
                  direction="horizontal"
                  inputRanges={[]}
                  maxDate={new Date()}
                  //showDateDisplay={true}
                  //  showPreview={formData}
                  //  onShownDateChange={(item) => {console.log('YYYYYY')}}
                  autoclose={true}
                  //  initialFocusedRange={formData}
                  staticRanges={[
                    {
                      label: "Today",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate:defineds.today,
                        endDate:new Date(),
                      }),
                      isSelected() {
                        return false;
                      },
                    },{
                      label: "Yesterday",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.yesterday,
                        endDate: defineds.eyesterday,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                     {
                      label: "Last 7 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last7days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    },   
                    {
                      label: "Last 15 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last15days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                    {
                      label: "Last 30 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last30days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, {
                      label: "WTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfWeek,
                        //  endDate: defineds.endOfWeek,
                        endDate:
                          new Date().getTime() <= defineds.endOfWeek.getTime()
                            ? new Date()
                            : defineds.endOfWeek,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                    {
                      label: "MTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfMonth,
                        //  endDate: defineds.endOfMonth,
                        endDate:
                          new Date().getTime() <= defineds.endOfMonth.getTime()
                            ? new Date()
                            : defineds.endOfMonth,
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                    {
                      label: "QTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfQuarter,
                        //endDate: defineds.endOfQuarter,
                        endDate:
                          new Date().getTime() <=
                          defineds.endOfQuarter.getTime()
                            ? new Date()
                            : defineds.endOfQuarter,
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                    
                    {
                      label: "YTD",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.startOfYear,
                        //endDate: defineds.endOfYear,
                        endDate:
                          new Date().getTime() <= defineds.endOfYear.getTime()
                            ? new Date()
                            : defineds.endOfYear,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                    {
                      label: "Till Date",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date("2000-01-01"),
                        endDate: new Date(),
                      }),
                      isSelected() {
                        return false;
                      },
                    },
                  ]}
                />
              </Form.Group>
            </div>
          ) : null}
        </div>
        </div>
      );
    }else{
      return (
        <div className="section-date-range1" id="in-focus">
        <div
          className={`date-range-picker${className ? " " + className : ""}${
            fullWidth ? " full-width" : ""
          }`}
        >
          <button
            type="button"
            className="btn btn-primary btn-filter date-range-picker__toggle-button"
            onClick={this.toggleDateSelectPanel}
          >
            {showDateSelectPanel ? (
              <i className="date-range-picker__toggle-button__icon mdi mdi-close"></i>
            ) : (
              <i className="date-range-picker__toggle-button__icon mdi mdi-calendar-range mr-2"></i>
            )}

            <span className="date-range-picker__toggle-button__text">
              {visibleDate}
            </span>
          </button>
          {showDateSelectPanel ? (
            <div
              className={
                showDateSelectPanel
                  ? "custom-filter-wrap"
                  : "custom-filter-wrap hide-calendar"
              }
              // tabindex="0"
              // onBlur={() => this.toggleDateSelectPanel(false)}
            >
              <Form.Group className="row m-0">
                <DateRangePicker
                  onChange={this.handleOnChangeNews}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={ranges}
                  direction="horizontal"
                  inputRanges={[]}
                  maxDate={new Date()}
                  //showDateDisplay={true}
                  //  showPreview={formData}
                  //  onShownDateChange={(item) => {console.log('YYYYYY')}}
                  autoclose={true}
                  //  initialFocusedRange={formData}
                  staticRanges={[
                    {
                      label: "Today",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate:defineds.today,
                        endDate:new Date(),
                      }),
                      isSelected() {
                        return false;
                      },
                    },{
                      label: "Yesterday",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.yesterday,
                        endDate: defineds.eyesterday,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                     {
                      label: "Last 7 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last7days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    },   
                    {
                      label: "Last 15 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last15days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    }, 
                    {
                      label: "Last 30 Days",
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: defineds.last30days,
                        endDate: defineds.today,
                      }),
                      isSelected() {
                        return false;
                      },
                    }
                  ]}
                />
              </Form.Group>
            </div>
          ) : null}
        </div>
        </div>
      );
    }
  }

}

export default DateFilter;

