import React from "react";
import moment from "moment";

function EventCard(props) {
  const eventDate = moment(props.event_date || props.eventdate).format(
    "DD MMM YYYY"
  );
  const eventName = props.eventname || props.event_name;
  const flag = props.flag || props.country_flag;
  const country = props.country_name || "";
  const eventUuid = props.eventuuid || props.event_uuid || "";

  return (
    <div
      className={`event-card${props.className ? " " + props.className : ""}`}
      onClick={props.onClick}
    >
      <div className="event-card__flag-container">
        <img
          className="event-card__flag-image"
          src={`data:image/png;base64,${flag}`}
          alt={country}
          data-toggle="tooltip"
          title={country}
        />
      </div>

      <div className="event-card__content-container">
        <div className="event-card__event-name-container">
          <p className="event-card__event-name">{eventName}</p>
        </div>
        <div>
          <span className="event-card__date">{eventDate}</span>
          <span className="blue-dot mx-4" />
          <span className="event-card__event-id">{eventUuid}</span>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
