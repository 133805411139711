import React, { Component } from "react";
import { get as lodashGet } from "lodash";
import ReactGA from "react-ga";
ReactGA.initialize("UA-154416724-3");

class TabContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIdx: props.defaultActiveTabIdx || 0,
    };
  }

  setActiveTabIdx = activeTabIdx => {
   
   if (this.props.setActiveTab)
   {
    this.props.setActiveTab(activeTabIdx);
   }  
   else this.setState({ activeTabIdx });
  };

  render() {
    const  activeTabIdx  = this.props.activeTab|| this.state.activeTabIdx;
    
    const { children } = this.props;
    const Tabs = children;

    const tabNames = Tabs.map(tab => {
      const tabName = lodashGet(tab, "props.name", "tab name");
      return tabName;
    });

    return (
      <div className="tab-container">
        <header className="tab-container__header uncopyable">
          {tabNames.map((tabName, i) => {
            if (document.location.hostname.search("http://localhost") !== -1) {
              ReactGA.pageview(window.location.pathname + "-" + tabName);
            }
            if (tabName === "tab name") {
              return true;
            } else {
              return (
                <span
                  className={`tab-name${activeTabIdx === i ? " active" : ""}`}
                  key={i}
                  onClick={() => this.setActiveTabIdx(i)}
                >
                  {tabName}
                </span>
              );
            }
          })}
        </header>
        <div className="tab-container__content">{Tabs[activeTabIdx]}</div>     
     </div>
    );
  }
}

export default TabContainer;
