import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";

const { SIGN_IN_PAGE_PATH, SIGN_UP_PAGE_PATH, CHANGE_PASSWORD ,WELCOME_PAGE_PATH,RESET_PASS_PAGE_PATH,RESET_PASS_SUCCESS_PAGE_PATH} = SITE_PATHS;

const SignInPage = asyncComponent(() =>
  import("pages/SignInPage").then((module) => module.default)
);

const SignUpPage = asyncComponent(() =>
  import("pages/SignUpPage").then((module) => module.default)
);

const ChangePasswordPage = asyncComponent(() =>
  import("pages/ChangePasswordPage").then((module) => module.default)
);

const WelcomePage = asyncComponent(() =>
import("pages/WelcomePage").then((module) => module.default)
);

const AuthorizationLayout = () => (
  <Switch>
    <Route path={SIGN_IN_PAGE_PATH} component={SignInPage} />
    <Route path={SIGN_UP_PAGE_PATH} component={SignUpPage} />
    <Route path={CHANGE_PASSWORD} component={ChangePasswordPage} />
    <Route path={WELCOME_PAGE_PATH} component={WelcomePage} />
    <Route path={RESET_PASS_PAGE_PATH} component={WelcomePage} />
    <Route path={RESET_PASS_SUCCESS_PAGE_PATH} component={WelcomePage} />
  </Switch>
);

export default AuthorizationLayout;
