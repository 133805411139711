import React from "react";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "components/SiteArt";
import { SITE_PATHS } from "config";
import AuthorizationService from "services/AuthorizationService";
//import Logo from "assets/images/logo/logowhite.png";
import Logo from "assets/images/logo/Icon 4.png";
import Logo_text from "assets/images/logo/Logo 3-text.png";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { store, userActions } from "services/Redux";
import { DEFAULT_USER_PROFILE_PICTURE } from "config";
import { get as lodashGet } from "lodash";
import Api, { AccountApi } from "services/Api";
// import NewsPopup from "pages/NewsPopup";
var isBase64 = require('is-base64');

const {
  DASHBOARD_PAGE_PATH,
  EVENTS_PAGE_PATH,
  COUNTRY_PAGE_PATH,
  GLOBAL_SEARCH_PAGE_PATH,
  RESOURCES_REGULATIONS_PAGE_PATH,
  PROFILE_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  INSIGHTS_PAGE_PATH,
  NEWS_PAGE_PATH,
  DATA_ASSETS,
} = SITE_PATHS;

var  SIDEBAR_NAV_OPTIONS = [
  {
    title: "Dashboard",
    icon: <SvgIcon className="nav-link__icon" icon="dashboard" />,
    linkTo: DASHBOARD_PAGE_PATH,
  },
  {
    title: "Country",
    icon: <SvgIcon className="nav-link__icon" icon="world" />,
    linkTo: COUNTRY_PAGE_PATH,
  },
  {
    title: "Search",
    icon: <SvgIcon className="nav-link__icon" icon="search" />,
    linkTo: GLOBAL_SEARCH_PAGE_PATH,
  },
  {
    title: "Regulations",
    // icon: <SvgIcon className="nav-link__icon" icon="server" />,
    icon: (
      <i className="mdi mdi-animation nav-link__icon nav-link__icon--mdi svg-icon"></i>
    ),
    linkTo: RESOURCES_REGULATIONS_PAGE_PATH,
  },
  {
    title: "Resources",
    icon: <SvgIcon className="nav-link__icon" icon="resources" />,
    linkTo: INSIGHTS_PAGE_PATH,
  },
  {
    title: "News",
    icon: <SvgIcon className="nav-link__icon" icon="newsPaper" fill="#fff" />,
    linkTo: NEWS_PAGE_PATH,
  },
  {
    title: "Data Assets",
    icon: <SvgIcon className="nav-link__icon" icon="dataAsset" fill="#fff" />,
    linkTo: DATA_ASSETS,
  }, 
];

class AppSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarLocked: false,
      isTeamMember:false,
    };
  }
  
  componentDidMount() {
    // this.fetchAmlNews();

    const storeData = store.getState();
    if(storeData.user.userData!==null){
      this.setState({isTeamMember:storeData.user.userData.data.team_member_exist});
    }
    // if(!storeData.user.userProfilePicture){
    if(!window.location.pathname.includes("share-events")){
        this.fetchProfileImage();
    }
    // }
  }

  toggleIsSidebarLocked = (state) => {
    this.setState({ isSidebarLocked: state });
  };

  handleLogout = (e) => {
    e.preventDefault();
    AuthorizationService.logout();
  };

  fetchProfileImage = ()=>{
    AccountApi.fetchUserProfileImage()
      .then((res) => {
        if (res.status === 200) {
          let imageLocalUrl = lodashGet(res, "data.data.download_link", null);
          window.sessionStorage.setItem('profile', imageLocalUrl);
          store.dispatch(userActions.setUserProfilePicture(imageLocalUrl));
        } else {
          store.dispatch(userActions.setUserProfilePicture(DEFAULT_USER_PROFILE_PICTURE));
        }
      })
      .catch((err) => {
        store.dispatch(userActions.setUserProfilePicture(DEFAULT_USER_PROFILE_PICTURE));
      });
  }

  render() {
    const { isSidebarLocked,isTeamMember } = this.state;
    const { userProfilePicture } = this.props;
    // alert(userProfilePicture)

    return (
      <div
        className={`site-navigation-panel uncopyable${
          isSidebarLocked ? " is-locked" : ""
        }`}
      >
        <div className="brand-logo-container">
          <img className="brand-logo-img" src={Logo} alt="jurnix logo" />{" "}
          <span className="nav-link__title  nav-link__title--brand-name logo-white-clor">
          <img className="brand-logo-img-text" src={Logo_text} alt="jurnix logo" />{" "}
          </span>
        </div>
        {/* <NewsPopup/> */}
        <div className="site-navigation-panel-lock__container">
          <input
            type="checkbox"
            id="site-navigation-panel-lock__toggle-input"
            className="site-navigation-panel-lock__toggle-input"
            onClick={(e) => this.toggleIsSidebarLocked(e.target.checked)}
          />
          <label
            className="site-navigation-panel-lock__toggle-input-label"
            htmlFor="site-navigation-panel-lock__toggle-input"
          >
            <span
              className={`site-navigation-panel-lock__icon-unlock mdi mdi-lock-open-variant${
                isSidebarLocked ? " hidden" : ""
              }`}
            />
            <span
              className={`site-navigation-panel-lock__icon-lock mdi mdi-lock${
                !isSidebarLocked ? " hidden" : ""
              }`}
            />
          </label>
        </div>

        <nav className="site-navigation-panel__nav">
          <ul>
            {SIDEBAR_NAV_OPTIONS.map((navItem, i) => {
              return (
                <li key={i} data-toggle="tooltip" title={navItem.title} className={navItem.title==='Resources'?'posrel':''}>
                  {/* {navItem.title==='Resources'?<span className="badge redBadge">NEW</span>:''} */}
                  
                  <NavLink className="nav-link" to={navItem.linkTo || "#"}>
                    <span className="nav-link__icon-container">
                      {navItem.icon}
                    </span>
                    <span className="nav-link__title">{navItem.title}</span>
                  </NavLink>
                </li>
              );
            })}
            {!window.location.pathname.includes("share-events")?<>
            {isTeamMember?"":<li data-toggle="tooltip" title="Admin Panel">
               <NavLink className="nav-link" to={PROFILE_PAGE_PATH}>
                    <span className="nav-link__icon-container">
                    <SvgIcon className="nav-link__icon" icon="user-settings" />
                    </span>
                    <span className="nav-link__title">Admin Panel</span>
                  </NavLink>
            </li>}

            <li className="mt-auto" data-toggle="tooltip" title="Profile">
              <NavLink
                className="nav-link nav-link--profile"
                to={PROFILE_EDIT_PAGE_PATH}
              >
                <span className="nav-link__icon-container nav-link__icon-container--profile">
                  <img
                    className="nav-link__icon p-0"
                    // src={userProfilePicture}
                    src={isBase64(userProfilePicture)?`data:image/png;base64,${userProfilePicture}`:userProfilePicture}
                    alt="user-profile"
                  />
                </span>
                <span className="nav-link__title">Profile</span>
              </NavLink>
            </li>

            <li data-toggle="tooltip" title="Logout">
              <NavLink
                className="nav-link nav-link__logout"
                to="/login"
                title="Logout"
                onClick={this.handleLogout}
              >
                <span className="nav-link__icon-container">
                  <FiLogOut color="white" size="20" />
                </span>
                <span className="nav-link__title">Logout</span>
              </NavLink>
            </li></>:""}
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfilePicture: state.user.userProfilePicture,
  userProfileConstant: state.user.userProfileConstant,
});

export default connect(mapStateToProps)(AppSidebar);
