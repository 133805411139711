import React, { Component } from "react";
import { SvgIcon } from "components/SiteArt";

class SearchInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInputRef: null,
    };
  }

  componentDidMount() {}

  addEnterKeyListenerToInput = () => {
    const _addEnterKeyListenerToInput = new Promise((resolve, reject) => {
      const { searchInputRef } = this.state;

      if (searchInputRef) {
        searchInputRef.addEventListener("keyup", ({ key }) => {
          if (key === "Enter") {
            const { onEnterKeyPress } = this.props;

            if (onEnterKeyPress instanceof Function) {
              onEnterKeyPress();
            }
          }
        });
      }

      resolve();
    });

    return _addEnterKeyListenerToInput;
  };

  setInputRef = (ref) => {
    const _setInputRef = new Promise((resolve, reject) => {
      this.setState({ searchInputRef: ref }, () => {
        resolve();
      });
    });

    return _setInputRef;
  };

  handleSetInputRef = (ref) => {
    this.setInputRef(ref).then(this.addEnterKeyListenerToInput);
  };

  render() {
    const { hideIcon, inputClassName, className } = this.props;

    return (
      <div
        className={`search-input-field__container${
          className ? " " + className : ""
        }`}
      >
        {hideIcon ? null : (
          <SvgIcon
            className="search-input-field__search-icon"
            icon="search"
            color="gray-light"
            size="small"
          />
        )}

        <input
          {...this.props}
          className={`search-input-field__input${
            inputClassName ? " " + inputClassName : ""
          }`}
          ref={this.handleSetInputRef}
        />
      </div>
    );
  }
}

export default SearchInputField;
