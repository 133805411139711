import React, { Component } from 'react';

class EventTimeLineCard extends Component {
  constructor() {
    super();
    this.state = {btnStatus:false };
  }

  readMore=(e, count)=>{
      e.preventDefault();
      this.setState({btnStatus:true});
  }

  readLess=(e, count)=>{
      e.preventDefault();
      this.setState({btnStatus:false});
  }

  showEvents(events) {
   let newEvent = (events !== "")?events:[];
    let timeLineArr = [];
    for(let x in newEvent){
      timeLineArr.push({
        title : x,
        date : newEvent[x].join(', '),
      })
     }
     
      if(timeLineArr.length > 0 ){
        return (
           <div className="row">
          { (timeLineArr.map((event, i) => {

            if(!this.state.btnStatus){
              if(i<=3){
                return (
                  <div className="col-md-3" key={i}>
                      <div className="mb-2 latest--events h-100 event-tl timeline-h">
                         <div className="event-date-mr">{event.date}</div>
                         <span className="event-blue-circle"></span>
                         <div className="event-des">{event.title}</div>
                      </div>
                  </div>
                      );
              }else return null;
            }else{
              return (
                <div className="col-md-3" key={i}>
                    <div className="mb-2 latest--events h-100 event-tl timeline-h">
                       <div className="event-date-mr">{event.date}</div>
                       <span className="event-blue-circle"></span>
                       <div className="event-des">{event.title}</div>
                    </div>
                </div>
                    );
                }
              })
            )}
            <div className="col-md-12">
              {(!this.state.btnStatus) ?
                ((timeLineArr.length>4) &&  <span className="read-btn" onClick={(e)=>this.readMore(e)}>Read More</span>)
              :
                (<span className="read-btn" onClick={(e)=>this.readLess(e)}>Read Less</span>)
              }
              </div>
            </div>
          )
        }
       return null;
  }
  showNewEvents(events){
let timeLineArr = (events !== "")?events:[];
timeLineArr.sort(function compare(a, b) {
  var dateA = new Date(a.TimelineDate);
  var dateB = new Date(b.TimelineDate);
  return  dateB-dateA;
});
if(timeLineArr.length > 0 ){
  return (
     <div className="row">
    { (timeLineArr.map((event, i) => {

      if(!this.state.btnStatus){
        if(i<=3){
          return (
            <div className="col-md-3" key={i}>
                <div className="mb-2 latest--events h-100 event-tl timeline-h">
                   <div className="event-date-mr">{event.UiDate}</div>
                   <span className="event-blue-circle"></span>
                   <div className="event-des">{event.TimelineAction}</div>
                </div>
            </div>
                );
        }else return null;
      }else{
        return (
          <div className="col-md-3" key={i}>
            <div className="mb-2 latest--events h-100 event-tl timeline-h">
                   <div className="event-date-mr">{event.UiDate}</div>
                   <span className="event-blue-circle"></span>
                   <div className="event-des">{event.TimelineAction}</div>
                </div>
          </div>
              );
          }
        })
      )}
      <div className="col-md-12">
        {(!this.state.btnStatus) ?
          ((timeLineArr.length>4) &&  <span className="read-btn" onClick={(e)=>this.readMore(e)}>Read More</span>)
        :
          (<span className="read-btn" onClick={(e)=>this.readLess(e)}>Read Less</span>)
        }
        </div>
      </div>
    )
  }
 return null;

  }
  render() {

    return (
      <>
      { Object.keys(this.props.eventTimeline).length >0  &&
       <div className="latest-container overflow-auto latest-event-slider event-detail-box">
         {console.log(typeof(this.props.flag))}
         { this.props.flag===0?this.showEvents(this.props.eventTimeline):this.showNewEvents(this.props.eventTimeline)}
       </div>
      }
      </>
    );
  }
 }

export default EventTimeLineCard;
