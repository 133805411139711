import React, { Component } from "react";
import moment from 'moment';

class SiteFooter extends Component {
  render() {
    return (
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-left">
              <a
                className="footer-link"
                href="https://www.amlpenalties.com/disclaimer"
              >
                Disclaimer
              </a>
              <a
                className="footer-link"
                href="https://www.amlpenalties.com/privacy-policy"
              >
                Privacy Policy
              </a>
              <a
                className="footer-link"
                href="https://www.amlpenalties.com/terms-and-conditions"
              >
                Terms Of Services
              </a>
              <a
                className="footer-link"
                href="https://www.amlpenalties.com/contact-us"
              >
                Contact Us
              </a>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <small className="copyright-text">
                © Copyright {moment().format('YYYY')} AML Penalties
              </small>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default SiteFooter;
