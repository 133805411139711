import ApiWrapper from "./ApiWrapper";
import MapDataApi from "./MapDataApi";
import GraphDataAPi from "./GraphDataAPi";
import EventsApi from "./EventsApi";
import AccountApi from "./AccountApi";
import CountryApi from "./CountryApi";
import ResourcesApi from "./ResourcesApi";
import SearchApi from "./SearchApi";
import PinNoteApi from "./PinNoteApi";
import DocumentApi from "./DocumentApi"

export default ApiWrapper;

export {
  MapDataApi,
  GraphDataAPi,
  EventsApi,
  AccountApi,
  CountryApi,
  ResourcesApi,
  SearchApi,
  PinNoteApi,
  DocumentApi,
};
