import {
  SET_USER_DATA,
  SET_USER_PROFILE_PICTURE,
  SET_USER_PROFILE_CONSTANT,
  DESTROY_SESSION
} from "../Actions/UserActions";

import { DEFAULT_USER_PROFILE_PICTURE } from "config";

const INITIAL_STATE = {
  userData: null,
  userProfilePicture: null,
  userProfileConstant: DEFAULT_USER_PROFILE_PICTURE
};

function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };

    case SET_USER_PROFILE_PICTURE:
      return {
        ...state,
        userProfilePicture: action.data,
      };
    case SET_USER_PROFILE_CONSTANT:
      return {
        ...state,
        userProfileConstant: DEFAULT_USER_PROFILE_PICTURE,
      };
    case DESTROY_SESSION:
      return {
        state: undefined,
      };

    default:
      return state;
  }
}

export default UserReducer;
