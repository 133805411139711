import React from "react";
import { Route, Redirect } from "react-router-dom";
import { get as lodashGet } from "lodash";
import { store, userActions } from "services/Redux";

import Cookies from "js-cookie";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const loginCheckTokenFromCookies =  Cookies.get("temp_id");
  if(loginCheckTokenFromCookies===undefined){
    store.dispatch(userActions.setUserData(null));
  }
  const storeData = store.getState();
  const loginCheckToken = lodashGet(
    storeData, 
    "user.userData.data.access",
    null
  );

  const redirectUrl = props => {
    if (props.location.pathname.includes("resources")) {
      let tempVar = props.location.pathname.split("/");
      if (!!tempVar[2])
      {
        let tempVar1 = tempVar[2].split("%20").join(" ");
        sessionStorage.setItem("insight-type", tempVar1);
        // sessionStorage.setItem("returnUrl", "/dashboard" );
        window.history.pushState({}, '', '/resources');
      }
    } 
  };

  const returnUrl = props => {
    if (props.location.pathname.includes("resources")) {
      let tempVar = props.location.pathname.split("/");
      if (!!tempVar[2])
      {
        let tempVar1 = tempVar[2].split("%20").join(" ");
        sessionStorage.setItem("insight-type", tempVar1);
        sessionStorage.setItem("returnUrl", "/" +tempVar[1]);
      }
      else
      {
        sessionStorage.setItem("insight-type", "Weekly Bulletin");
        sessionStorage.setItem("returnUrl", "/resources");
      }

    }

    else {
     if(props.location.search==='')
      sessionStorage.setItem("returnUrl", props.location.pathname);
      else
      sessionStorage.setItem("returnUrl", props.location.pathname+props.location.search);
    }
     };

  return (
    <Route
      {...rest}
      render={props => {
        if (!!loginCheckToken) {
          redirectUrl(props);
          return <Component {...props} />;
        } else {
          returnUrl(props);
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
