import React from "react";

function TextField(props) {
  return (
    <input
      {...props}
      className={`input-text-field${props.className ? " " + props.className : ""}${props.disabled ? " is-disabled": ""}`}
    />
  );
}

export default TextField;
