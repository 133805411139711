import Api from "./ApiWrapper";
import {
  REASON_COUNT_GRAPH_DATA_ENDPOINT,
  REASON_SUM_GRAPH_DATA_ENDPOINT,
  INDUSTRY_COUNT_GRAPH_DATA_ENDPOINT,
  INDUSTRY_SUM_GRAPH_DATA_ENDPOINT,
  EVENTS_COUNT_GRAPH_DATA_ENDPOINT,
  EVENTS_TOTAL_COUNT_GRAPH_DATA_ENDPOINT,
  EVENTS_FINES_GRAPH_DATA_ENDPOINT,
} from "./ApiEndpoints";

function fetchReasonCountGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${REASON_COUNT_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchReasonSumGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${REASON_SUM_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchEventsCountGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${EVENTS_COUNT_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchEventsTotalCountGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${EVENTS_TOTAL_COUNT_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchEventsFinesGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${EVENTS_FINES_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchIndustryCountGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${INDUSTRY_COUNT_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

function fetchIndustrySumGraphData(timeline, country) {
  const queryParams = `?timeline=${timeline || ""}&country=${country || ""}`;
  return Api.get(`${INDUSTRY_SUM_GRAPH_DATA_ENDPOINT}${queryParams}`);
}

const GraphDataAPi =  {
  fetchReasonCountGraphData,
  fetchReasonSumGraphData,
  fetchEventsCountGraphData,
  fetchEventsTotalCountGraphData,
  fetchEventsFinesGraphData,
  fetchIndustryCountGraphData,
  fetchIndustrySumGraphData,
};

export default GraphDataAPi;