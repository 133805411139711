import Api from "./ApiWrapper";
import {
  NOTES_LIST_ENDPOINT,
  PINNED_ITEM_ENDPOINT,
  UNPIN_ITEM_ENDPOINT,
  NOTES_TAG_ENDPOINT,
} from "./ApiEndpoints";


function fetchPinnedItem(type, search, ordering) {
  const queryParams = `?type=${type || ""}&search=${search || ""}&ordering=${ordering || ""}`;
  return Api.get(`${PINNED_ITEM_ENDPOINT}${queryParams}`);
}

function fetchNotesItem(search, ordering) {
  const queryParams = `?search=${search || ""}&ordering=${ordering || ""}`;
  return Api.get(`${NOTES_LIST_ENDPOINT}${queryParams}`);
}

function deletePinItem(id) {
  return Api.delete(`${UNPIN_ITEM_ENDPOINT}/${id}`);
}

function addNotesItem(noteData) {
  return Api.post(`${NOTES_LIST_ENDPOINT}/`, { ...noteData });
}

function editNotesItem(id, noteData) {
  return Api.put(`${NOTES_LIST_ENDPOINT}/${id}`, { ...noteData });
}

function fetchSingleNotesItem(id) {
  return Api.get(`${NOTES_LIST_ENDPOINT}/${id}`);
}

function fetchTagList(id) {
  return Api.get(`${NOTES_LIST_ENDPOINT}/${id}`);
}

function deleteNotesItem(id) {
  return Api.delete(`${NOTES_LIST_ENDPOINT}/${id}`);
}

function fetchNoteTags(id) {
  return Api.get(`${NOTES_TAG_ENDPOINT}`);
}



const PinNoteApi = {
fetchNotesItem,
fetchPinnedItem,
deletePinItem,
deleteNotesItem,
fetchSingleNotesItem,
addNotesItem,
fetchNoteTags,
editNotesItem
};

export default PinNoteApi;
