import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { MainLayout, AuthorizationLayout, BlankLayout } from "Layouts";

const {
  SIGN_IN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  CHANGE_PASSWORD,
  DOCUMENT_VIEWER_PAGE_PATH,
  SHARE_EVENTS,
  WELCOME_PAGE_PATH,
  RESET_PASS_PAGE_PATH,
  RESET_PASS_SUCCESS_PAGE_PATH
} = SITE_PATHS;

const SiteRoutes = () => (
  <Switch>
    <Route
      exact
      path={[SIGN_IN_PAGE_PATH, SIGN_UP_PAGE_PATH, CHANGE_PASSWORD,WELCOME_PAGE_PATH,RESET_PASS_PAGE_PATH,RESET_PASS_SUCCESS_PAGE_PATH]}
      component={AuthorizationLayout}
    />

    <Route
      exact
      path={[
        DOCUMENT_VIEWER_PAGE_PATH,
        `${DOCUMENT_VIEWER_PAGE_PATH}/:documentId`,
       `${SHARE_EVENTS}`,
      ]}
      component={BlankLayout}
    />

    <Route path="/" component={MainLayout} />
  </Switch>
);

export default SiteRoutes;
