import React from "react";
import moment from "moment";

function EventCard(props) {
  const newsDate = moment(props.DatePublished).format("DD MMM YYYY");

  return (
    <div
      className={`news-card uncopyable${
        props.className ? " " + props.className : ""
      }`}
      onClick={props.onClick}
    >
      <h3 className="news-card__title">{props.Heading}</h3>
      <span className="news-card__date">{newsDate}</span>

      <div className="news-card__content-container">
        <div className="news-card__image-container">
          {props.ImageUrl && (
            <img className="news-card__image" src={props.ImageUrl} alt="flag" />
          )}
        </div>
        <p className="news-card__description">{props.Description}</p>
      </div>

      {/* <div className="news-card__tags-container"></div> */}
    </div>
  );
}

export default EventCard;
