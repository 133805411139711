import React ,{useState,useEffect} from "react";
import { TickerCard } from "components/Cards";
import TabContainer from "components/TabContainer";
import { Accordion, Card, Button } from "react-bootstrap";
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import { Loader } from "components/SiteArt";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,

} from "react-share";
import toaster from "components/Toaster/Toaster";
function ShareEventContainer() {
  let EventName ;
  let metakeywordstemp=[];
  let metakeywords="";
 const [eventName,setEventName] =useState("");
 const [ID,setID] =useState("");
 const [eventDate,setEventDate] =useState("");
 const [eventID,setEventID] =useState("");
 const [eventCountry,setEventCountry] =useState("");
 const [countryFlag,setCountryFlag] =useState("");
 const [eventFine,setEventFine] =useState("");
 const [eventImprisonment,setEventImprisonment] =useState("");
 const [eventSummary,setEventSummary] =useState("");
 const [eventKeyword,setEventKeyword] =useState([]);
 const [eventEntities,setEventEntities] =useState([]);
 const [eventFetching ,setEventFetching]=useState(false);
 const [showPage,setShowPage]=useState(true);
 const [eventKeywordTags,SetEventKeywordTags]=useState("");
  useEffect(()=>{
    let temp =window.location.href.split('?');
     EventName=temp[1];
     setID(EventName)
       setEventFetching(true)
      fetcheventsDetails(EventName);
  },[])
  const fetcheventsDetails=(EventId)=>{
    let eventsAPI =`${process.env.REACT_APP_BASE_URL}/cms/event_search_publically_with_name/${EventId}` ;
    // fetch(eventsAPI, {
    //   method: "GET",
    //   headers: { "Content-Type": "application/json" },
    //   // body: JSON.stringify(json),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //    console.log(response)
    //   });
    axios.get(eventsAPI).then((res)=>{
      if(res.status===200){
        if(res.data.message ==="Event Found"){
        setShowPage(false);
        setEventFetching(false);
        setEventName(res.data.data[0].EventName);
        setEventID(res.data.data[0].EventUUID);
        setEventCountry(res.data.data[0].CountryName);
        setEventDate(moment(res.data.data[0].EventDate).format("DD MMM YYYY"));
        setCountryFlag(res.data.data[0].flag);
        setEventFine(res.data.data[0].fine===null||res.data.data[0].fine===0?"-":numeral(res.data.data[0].fine).format("0.00 a"));
        setEventImprisonment(res.data.data[0].imprisonment===null||res.data.data[0].imprisonment===0?0:numeral(res.data.data[0].imprisonment).format("0,0"));
        setEventSummary(res.data.data[0].CaseSummary);
        setEventKeyword(res.data.data[0].EventTags);
        setEventEntities(res.data.data[0].CleanEntities);
        
      }
      else{
        setShowPage(true);
        setEventFetching(false);
      }
    }})

  
  }
useEffect(()=>{
let temparr = eventEntities.concat(eventKeyword); 
let temp =temparr.join([','])
  SetEventKeywordTags(temp)
   
},[eventEntities])
  function ReadMoreBtn() {
    return <Link className="read-btn" to={`/event/${eventID}`}>Click Here To Read More</Link>;
  }
  return (<>
  <MetaTags>
  <meta property="og:image"  content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/AMLPenalties_EventsDynamicSnippet.png" />
  <meta property="og:description" content={eventName} />
  <meta name="description" content={eventName} />
  <meta name="keywords" content={eventKeywordTags} /> 
  <meta
    property="twitter:description"
    content={eventName}
  ></meta>
  <meta name="twitter:card" content="summary_large_image" />
  <meta property="twitter:image" content="https://aml-demo-cms.s3.us-west-2.amazonaws.com/social-snippet/AMLPenalties_EventsDynamicSnippet.png"></meta>
  </MetaTags>
    <main className="main-layout__main">
     { eventFetching?   <div className="py-5 my-5 text-center">
        <Loader />
      </div>:
     !showPage? <div className="event-detail-page site-page shareEvents">
        <div className="event-detail-page__top-section site-page__top-section">
          <div className="bg-world-map" />
          <header className="site-page__header row mx-0">
            <div className="col-xl-6 px-0">
              <h1 className="site-page__heading">
              {eventName}&nbsp;&nbsp;
                {/* { isSubPage &&
            <span className="input-field form-group fa-icon-cricle">
               <a href={doclink} target="_blank" rel="noopener noreferrer" title="View more details"><i data-toggle="tooltip" className="fa fa-external-link" ></i></a>
              
            </span>
          } */}
              </h1>

              <div className="mt-3 event-basic-info">
                <span className="event-basic-info__item mr-3">{eventID}</span>
                <span className="blue-dot mr-3" />
                <span className="event-basic-info__item mr-3">{eventDate}</span>
                <span className="event-country-flag__container mr-3">
                  <img
                className="event-country-flag__image"
                 src={`data:image/png;base64,${countryFlag}`}
                alt={eventCountry}
              />
                </span>
                <span className="event-basic-info__item">{eventCountry}</span>
              </div>
            </div>

            <div className="col-xl-6 mt-4 mt-xl-0 px-0">
              <div className="row">
                <div className="col-sm-6 col-md-auto col-xl-6 text-xl-right">
                  <TickerCard
                    variant="B"
                    title="Penalty (USD)"
                    icon={<i className="fa fa-gavel font-icon"></i>}
                    isFontIcon={true}
                    value={eventFine}
                  />
                </div>

                <div className="col-sm-6 col-md-auto col-xl-6 mt-3 mt-sm-0">
                  <TickerCard
                    variant="B"
                    title="Imprisonments (months)"
                    icon={<i className="fa fa-user-secret font-icon"></i>}
                    isFontIcon={true}
                    value={eventImprisonment}
                  />
                </div>
              </div>
            </div>
          </header>
        </div>
      <div className="reportShareIcons eventShareIcons float-right mr-5 mt-3">
            <img
              class="mousePointerCopyImg "
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABQ0lEQVRoge2YTW7CMBBGP6OINiXbShyBTQ+BuAgoHIJduyR3IOo92CBxHbb8loC7ikhR1TrJjDNB89aJnZcv9oxjQEj4FNu6YxxOqQGAKJq8brefG9f7OnUn5uJyNuteb9x3vV6sCGAG16yzcpURLAKUkREuArjKtEAEcJGhFbGYkY73g79lSEUOX2nSlExAv/enycvzNLTWvtcd93fM4JoFyyiajIp1hjSRfO/fHxcfvMnYt/s6Q7zYb9H7/swYdq1mZALmCYa7XZqE3RgwmJcZIV93TpxY64jPZACmRHLKJ1N1F/VQ2f0k46lF4Zfx2GvxynhuGvlkGuh+eWQMZa9VFYpnaMl55H9I6kjVN1o3ySIPk4iKSENFpKEi0lARaaiINFREGioiDRWRhopIg/nfrxsUZ/eHSURFpKEi0lARaXwDInmsdRhBdEUAAAAASUVORK5CYII="
              onClick={() => {
                const el = document.createElement("input");
                el.value = window.location.href;
                document.body.appendChild(el);
                el.select();
                document.execCommand("copy");
                document.body.removeChild(el);
                toaster.show({
                  message: "Link copied to clipboard ",
                  intent: "Success",
                });
              }}
            ></img>
            <EmailShareButton 
            url={window.location.href}>
                <i className="fa fa-envelope" aria-hidden="true"></i>
            </EmailShareButton>
                <FacebookShareButton
                  url={window.location.href}
                >
                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                </FacebookShareButton>



                <TwitterShareButton
                  url={window.location.href}
                >
                  <i className="fa fa-twitter-square" aria-hidden="true"></i>
                </TwitterShareButton>

                <LinkedinShareButton
                  url={window.location.href}>
                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                </LinkedinShareButton>
        </div>
        <div className="mt-2 event-detail-page__content minheightnone">
          <TabContainer>
            <div name="Case Summary">{eventSummary}&nbsp;... &nbsp;{ReadMoreBtn()}</div>

            <div name="Agencies"><div className=" mt-3">{ReadMoreBtn()}</div></div>

            <div name="Reasoning For Penalties"><div className=" mt-3">{ReadMoreBtn()}</div></div>

            <div name="Organizations"><div className=" mt-3">{ReadMoreBtn()}</div></div>

            <div name="Individuals"><div className=" mt-3">{ReadMoreBtn()}</div></div>

            <div name="Sources"><div className=" mt-3">{ReadMoreBtn()}</div></div>
          </TabContainer>
        </div>

        <div className="event-tags">
         {(eventKeyword || []).length? <div>
          <h2 className="site-page__heading">Keyword</h2>
          <div className="tag-bx">
            {eventKeyword!==[] ?eventKeyword.map((tag,i)=>{
              return <a key={i}>{tag}</a>
            }):""}
          </div>
          </div>:null}

          { (eventEntities||[]).length?<div className="mt-30">
            <h2 className="site-page__heading">Entities</h2>
            <div className="tag-bx">
            {eventEntities!==[] ?eventEntities.map((tag,i)=>{
              return <a key={i}>{tag}</a>
            }):""}
            </div>
          </div>:null}
        </div>

        <div>
          <div className="py-2 similar-events--new">
            <h2 className="site-page__heading">Event Timeline</h2>
            <div className=" ml-2rem">{ReadMoreBtn()}</div>
          </div>
        </div>

        <div className="event-map-card-share-events">
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <>
                <span className="accordian-card__button-container d-flex">
                  <span><h2 className="site-page__heading">Association Map</h2></span>
                  <span className="input-field form-group fa-icon-cricle event-cursor">
                    {/*<div onClick={(e)=>History.push('/event/'+eventUuid)} data-toggle="tooltip" title="Open in a new window" className="fa fa-share"> </div>*/}
                  </span>
                  <Accordion.Toggle as={Button}
                     variant="link"
                     className="accordian-card__toggle-button"
                     eventKey="0">
                  </Accordion.Toggle>
                </span>
              </>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body className="">
                    <div className=" mt-3">{ReadMoreBtn()}</div>
               </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      </div>:<div className="my-5 text-center"> No Event Found</div>
}
    </main>
    </>
  );
}

export default ShareEventContainer;
