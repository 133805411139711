import React from "react";
import { EcoBarGraph } from "components/Chart";

function DataTable(props) {
  return (
    <div className="d-flex flex-column align-items-center w-100 ">
      <table className="table text-center w-100">
        <thead>
          <tr>
            <th>Year</th>

            {props.name.includes('%')?<th>Growth Rate</th>:<th>Value</th>}
          </tr>
        </thead>
        <tbody>
          {(props.tableData || []).map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.year}</td>
                <td>{item.value || "NA"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function EconomicDataInfoCard(props) {
  return (
    <div className="economic-data-info-card" style={props.style}>
      {" "}
      <h3 className="economic-data-info-card__name">{props.name}</h3>
      <div className="economic-data-info-card__content">
        <div className="economic-data-info-card__graph-container mt-3">
          {/* {indicesGraphData instanceof Object ? ( */}
          <EcoBarGraph
            graphLabel={props.chartLabels}
            graphData={props.chartData}
          />
          {/* ) : null} */}
        </div>
        <div className="economic-data-info-card__table-container">
          <DataTable
            tableData={props.tableData}
            name={props.name}
            // rank={COUNTRY_INDICE_RANK[indice.key].rank}
          />
           <p className="country-indice-card__info">{props.info}</p>
        </div>
      </div>
    </div>
  );
}

export default EconomicDataInfoCard;
