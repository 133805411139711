const SITE_PATHS = {
    DEVELOPERS_GUIDE_PAGE_PATH: "/dev",
    DASHBOARD_PAGE_PATH: "/dashboard",
    SIGN_IN_PAGE_PATH: "/sign-in",
    SIGN_UP_PAGE_PATH: "/sign-up",
    EVENTS_PAGE_PATH: "/event",
    EVENT_DETAIL_PAGE_PATH: "/event", //path will be appended by "/Event_UID"
    COUNTRY_PAGE_PATH: "/country",
    COUNTRY_DETAIL_PAGE_PATH: "/country", //path will be appended by "/Country_Id"
    GLOBAL_SEARCH_PAGE_PATH: "/search",
    GLOBAL_HISTORY_PAGE_PATH:"/search-history",
    GLOBAL_SAVED_INPUT_PAGE_PATH:"/saved-searches",
    RESOURCES_REGULATIONS_PAGE_PATH: "/regulations",
    RESOURCE_REGULATIONS_DETAIL_PAGE_PATH:"/regulation",
    PROFILE_EDIT_PAGE_PATH: "/profile-edit",
    PROFILE_PAGE_PATH: "/manage-account",
    PIN_NOTE_PAGE_PATH: "/pinned-event",
    DOCUMENT_VIEWER_PAGE_PATH: "/doc",//path will be appended by "/document_resource_id"
    LOADER_URL: "/verify",
    CHANGE_PASSWORD: '/reset-password',
    WELCOME_PAGE_PATH: '/welcome',
    RESET_PASS_PAGE_PATH: '/reset-password-request',
    RESET_PASS_SUCCESS_PAGE_PATH: '/reset-password-success',
    INSIGHTS_PAGE_PATH:'/resources',
    NEWS_PAGE_PATH: "/news",
    FINCIN_DETAIL_PAGE_PATH: "/finCEN",//path will be appended by "/FINCIN_UID"
    REPORTS_DETAIL_PAGE_PATH:"/report",
    SANCTION_DETAIL_PAGE_PATH:"/sanction",//path will be appended by "/SANCTION_UID",
    not_found:"/page-not-found",
    DATA_ASSETS:"/dataassets",
    SHARE_EVENTS:"/share-events",
    INSIGHT_LIST:'/resource-list',
    INSIGHT_DETAIL_PAGE_PATH:'/resource-detail',
}

export default SITE_PATHS;