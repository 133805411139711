import React from "react";
import { Router } from "react-router-dom";
import { SiteRoutes } from "routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SITE_MESSAGES, SITE_SETTINGS } from "config";
import MomentUtils from "@date-io/moment";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "services/Redux";

import History from "services/History";
import "styles/main.scss";
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-154416724-3');
// ReactGA.pageview(window.location.pathname + window.location.search);
// if (document.location.hostname.search("http://localhost") !== -1) {
// History.listen(location => {
//     ReactGA.set({ page: location.pathname })
//     ReactGA.pageview(location.pathname)
// })
// }
class App extends React.Component {
  constructor(props) {
    super(props);

    if (SITE_SETTINGS.DEVELOPER_MODE) {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        alert(SITE_MESSAGES.RIGHT_CLICK_DISABLED_MESSAGE);
      });
    }
  }
//   componentDidMount() {
//     ReactGA.pageview(window.location.pathname)
// }


  render() {
    return (
      <div className="App uncopyable">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={History}>
                <SiteRoutes />
              </Router>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
