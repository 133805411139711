import iconCriticalityHigh from "assets/images/icons/icon-critical-high.png";
import iconCriticalityLow from "assets/images/icons/icon-critical-low.png";
import iconCriticalityMedium from "assets/images/icons/icon-critical-medium.png";
import iconCriticalityVeryLow from "assets/images/icons/icon-critical-very-low.png";

export function getCriticalityIconFromScore(score) {
  let icon = iconCriticalityVeryLow;

  if (score > 0) {
    icon = iconCriticalityLow;
  }
  if (score > 1.99) {
    icon = iconCriticalityMedium;
  }
  if (score > 3.8) {
    icon = iconCriticalityHigh;
  }

  return icon;
}

export function getCriticalityTextFromScore(score) {
  let icon = "Not Critical";

  if (score > 0) {
    icon = "Low";
  }
  if (score > 1.99) {
    icon = "Medium";
  }
  if (score > 3.8) {
    icon = "High";
  }

  return icon;
}
