import Api from "./ApiWrapper";
import { SEARCH_ENDPOINT } from "./ApiEndpoints";

function search(
  type,
  country,
  query,
  andQuery,
  orQuery,
  fromDate,
  toDate,
  level
) {
  let queryParams = `?type=${type || ""}&country=${country || ""}&search=${
    query || ""
  }&and_search_terms=${andQuery || ""}&`;
  queryParams += `or_search_terms=${orQuery || ""}&date_after=${
    toDate || ""
  }&date_before=${fromDate || ""}&level=${level || ""}`;

  return Api.get(`${SEARCH_ENDPOINT}${queryParams}`);
}

const SearchApi = {
  search,
};

export default SearchApi;
