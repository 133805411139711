import Api from "./ApiWrapper";
import {
  EVENTS_MAP_DATA_ENDPOINT,
  FINES_MAP_DATA_ENDPOINT,
  IMPRISONMENT_MAP_DATA_ENDPOINT,
  ENTITY_MAP_DATA_ENDPOINT,
  INDIVIDUAL_MAP_DATA_ENDPOINT,
  REGULATOR_MAP_DATA_ENDPOINT,
  HOME_TICKER_DATA_ENDPOINT,
} from "./ApiEndpoints";

function fetchEventsMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${EVENTS_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchFinesMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${FINES_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchImprisonmentMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${IMPRISONMENT_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchEntityMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${ENTITY_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchIndividualMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${INDIVIDUAL_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchRegulatorMapData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${REGULATOR_MAP_DATA_ENDPOINT}${queryParams}`);
}

function fetchTickerData(fromDate, toDate, country) {
  const queryParams = `?fromDate=${fromDate || ""}&toDate=${
    toDate || ""
  }&country=${country}`;

  return Api.get(`${HOME_TICKER_DATA_ENDPOINT}${queryParams}`);
}

const MapDataApi = {
  fetchEventsMapData,
  fetchFinesMapData,
  fetchImprisonmentMapData,
  fetchEntityMapData,
  fetchIndividualMapData,
  fetchRegulatorMapData,
  fetchTickerData,
};

export default MapDataApi;
