import React from "react";
import imgLogo from "assets/images/logo/Logo 3.png";

function AmlLogo(props) {
  return (
    <div className={`aml-logo__container${props.className ? " " + props.className : ""}`} onClick={()=>{window.location.replace('https://www.amlpenalties.com/')}}>
      <img
        className={`aml-logo__image${
          props.imageClassName ? " " + props.imageClassName : ""
        }`}
        src={imgLogo}
        alt="aml penalties"
      />
    </div>
  );
}

export default AmlLogo;
