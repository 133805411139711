import Api from "./ApiWrapper";
import {
  LOGIN_ENDPOINT,
  REGISTER_ENDPOINT,
  USER_DATA_ENDPOINT,
  USER_TICKER_DATA_ENDPOINT,
  SUBSCRIPTION_PLANS_LIST_ENDPOINT,
  TEAM_MEMBER_LIST_ENDPOINT,
  UPDATE_TEAM_MEMBER_SUBSCRIPTION_ENDPOINT,
  REMOVE_TEAM_MEMBER_ENDPOINT,
  ADD_TEAM_MEMBER_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  UPDATE_USER_DATA_ENDPOINT,
  USER_PROFILE_IMAGE_ENDPOINT,
  UPDATE_USER_PROFILE_IMAGE_ENDPOINT,
  BUY_PLAN_ENDPOINT,
  UPDATE_PLAN_ENDPOINT,
  REMOVE_PLAN_ENDPOINT,
  REFRESH_TOKEN,
  CONFIRM_EMAIL,
  SSO_CODE_LOGIN_ENDPOINT,
  SSO_RGEISTER_ENDPOINT,
  SSO_LOGIN_ENDPOINT,
  SSO_CHANGE_PASSWORD_ENDPOINT,
  RESET_FORGOT_PASSWORD_ENDPOINT,
  VERIFIED_EMAIL,
  SEARCH_RESULT_ENDPOINT,
  SEARCH_SAVED_INPUTS_ENDPOINT,
  SEARCH_SAVED_INPUTS_LIST_ENDPOINT,
  DELETE_SAVED_INPUTS_LIST_ENDPOINT,
  EDIT_SAVED_INPUTS_LIST_ENDPOINT,
  INSIGHTS_ENDPOINT,
  LATEST_INSIGHT,
  INSIGHT_DETAIL,
  SEND_MAIL_BASE_URL,
  NEWS_THEME_BASE_URL,
  NEWS_SEARCH_BASE_URL,
  REGULATION_TICKER,
  REGULATION_DETAIL_ID,
  FINCIN_LIST_DATA,
  FINCIN_DETAIL,
  REPORT_LIST_DATA,
  REPORT_DETAIL_ENDPOINT,
  SUBSCRIPTION_DETAIL,
  INVOICES_LIST,
  INVOICE_DETAIL,
  SANCTION_LIST_DATA,
  FINCEN_GLOBAL_SEARCH_ENDPOINT,
  SANCTION_GLOBAL_SEARCH_ENDPOINT,
  DATA_ASSETS_DATA,
  RECENT_REGULATION
} from "./ApiEndpoints";

function login(email, password) {
  const body = {
    email,
    password,
  };

  //return Api.post(`${LOGIN_ENDPOINT}`, body);
  return Api.post(`${SSO_LOGIN_ENDPOINT}`, body);
}

// API for login users with code and state recieved in queryparams
// Endpoints it can hold -> [SSO_CODE_LOGIN_ENDPOINT]
function loginSSO(code, state) {
  const body = {
    code,
    state,
  };

  return Api.post(`${SSO_CODE_LOGIN_ENDPOINT}`, body);
}

function changePasswordSSO(body) {
  return Api.post(`${SSO_CHANGE_PASSWORD_ENDPOINT}`, body);
}

function resetForgotPassword(body) {
  return Api.post(`${RESET_FORGOT_PASSWORD_ENDPOINT}`, body);
}

function register(
  firstName,
  lastName,
  organizationName,
  designation,
  country,
  email,
  password,
  confirmPassword
) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    organization_name: organizationName,
    designation,
    country,
    email,
    password,
    password2: confirmPassword,
  };

  //  return Api.post(`${REGISTER_ENDPOINT}`, body);
  return Api.post(`${SSO_RGEISTER_ENDPOINT}`, body);
}

function fetchAccountDetails() {
  return Api.get(USER_DATA_ENDPOINT);
}

function updateAccountDetails(data) {
  return Api.put(UPDATE_USER_DATA_ENDPOINT, data);
}

function fetchUserProfileImage() {
  return Api.get(USER_PROFILE_IMAGE_ENDPOINT);
}

function updateUserProfileImage(data) {
  return Api.post(UPDATE_USER_PROFILE_IMAGE_ENDPOINT, data);
}

function fetchTickerData() {
  return Api.get(USER_TICKER_DATA_ENDPOINT);
}

function fetchSubscriptionPlans() {
  return Api.get(SUBSCRIPTION_PLANS_LIST_ENDPOINT);
}

function fetchTeamMemberList() {
  return Api.get(TEAM_MEMBER_LIST_ENDPOINT);
}

function addTeamMember(data) {
  return Api.post(ADD_TEAM_MEMBER_ENDPOINT, data);
}

function removeTeamMember(id) {
  return Api.delete(`${REMOVE_TEAM_MEMBER_ENDPOINT}/${id}`);
}

function addTeamMemberSubscription(id) {
  const data = {
    user_ids: id,
  };

  return Api.post(UPDATE_TEAM_MEMBER_SUBSCRIPTION_ENDPOINT, data);
}

function removeTeamMemberSubscription(id) {
  const data = {
    user_id: id,
  };

  return Api.delete(UPDATE_TEAM_MEMBER_SUBSCRIPTION_ENDPOINT,{data: data});
}

function forgotPassword(recoveryEmail) {
  const data = {
    email: recoveryEmail,
  };

  return Api.post(FORGOT_PASSWORD_ENDPOINT, data);
}

function buyPlan(planCode, users) {
  const data = {
    plan_code: planCode,
    quantity: users,
  };

  return Api.post(BUY_PLAN_ENDPOINT, data);
}

function updatePlan(planCode, updatedUsers) {
  const data = {
    plan_code: planCode,
    quantity: updatedUsers,
  };

  return Api.put(UPDATE_PLAN_ENDPOINT, data);
}

function removePlan() {
  return Api.delete(REMOVE_PLAN_ENDPOINT);
}

function refreshToken(token) {
  return Api.post(REFRESH_TOKEN, token);
}

function fetchConfirmEmail() {
  return Api.get(CONFIRM_EMAIL);
}

function fetchVerifiedEmail(username, confirmation_code) {
  const data = {
    username: username,
    confirmation_code: confirmation_code,
  };
  return Api.post(VERIFIED_EMAIL, data);
}
function fetchGlobalSearchResult() {
  return Api.get(SEARCH_RESULT_ENDPOINT);
}
function saveGlobalSearchInput(inputData) {
  return Api.post(SEARCH_SAVED_INPUTS_ENDPOINT, inputData);
}
function fetchGlobalSearchInput() {
  return Api.get(SEARCH_SAVED_INPUTS_LIST_ENDPOINT);
}
function deleteSavedSeacrhInput(SavedInputID) {
  const queryParams = `/${SavedInputID}`;
  return Api.delete(`${DELETE_SAVED_INPUTS_LIST_ENDPOINT}${queryParams}`);
}
function editSavedSeacrhInput(id, data) {
  const queryParams = `/${id}`;
  return Api.put(`${EDIT_SAVED_INPUTS_LIST_ENDPOINT}${queryParams}`, data);
}
function sendMail(mailData) {
  return Api.post(SEND_MAIL_BASE_URL, mailData);
}

function fetchInsightData(newTab, page, page_size) {
  const queryParams = `${newTab}&pagination=${"yes"}&page=${page}&page_size=${page_size}`;
  return Api.get(`${INSIGHTS_ENDPOINT}${queryParams}`);
}
function fetchLatestInsight(){
  return Api.get(LATEST_INSIGHT);
}
function fetchInsightDetail(name) {
  return Api.get(`${INSIGHT_DETAIL}/${name}`);
}

// ------------------|NEWS ENDPOINTS |------------------

function fetchNewsByID(newsID) {
  const queryParams = `LinkId?linkUId=${newsID}`;
  return Api.get(`${NEWS_SEARCH_BASE_URL}${queryParams}`);
}

function newsTheme() {
  return Api.get(NEWS_THEME_BASE_URL);
}

function fetchNewsBySearch(
  country,
  ThemeName,
  dateFrom,
  dateTo,
  GlobalSearch,
  Tags,
  pageNumber,
  pageSize
) {
  const queryParams = `?projectId=${54}&country=${
    country || ""
  }&ThemeName=${ThemeName}&dateFrom=${dateFrom || ""}&dateTo=${
    dateTo || ""
  }&GlobalSearch=${GlobalSearch}&Tags=${Tags}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return Api.get(`${NEWS_SEARCH_BASE_URL}${queryParams}`);
}
//REGULATION TICKER
function fetchRegulationTicker() {
  return Api.get(REGULATION_TICKER);
}
//REGULATION DETAIl
function fetchRegulationDetail(id) {
  return Api.get(REGULATION_DETAIL_ID + id);
}

//REcent Regulations list 
function fetchRecentRegulationsList(limit, country, fromDate, toDate) {
  let queryParams = `?limit=${limit || ""}&country=${country || ""}`;
  queryParams += `&toDate=${toDate || ""}&fromDate=${fromDate || ""}`;
  return Api.get(`${RECENT_REGULATION}${queryParams}`);
}
//FINCIN LIST API FUNCTION
function fetchFinCenList(searchQuery) {
  const queryParams = `?search=${searchQuery || ""}`;
  return Api.get(`${FINCIN_LIST_DATA}${queryParams}`);
}
function fetchFinCenDetails(fincenUID) {
  return Api.get(FINCIN_DETAIL+fincenUID);
}
//INSIGHT REPORT LIST API FUNCTION
function fetchReportList() {
  return Api.get(REPORT_LIST_DATA);
}
function fetchReportDetail(ReportName) {
  return Api.get(REPORT_DETAIL_ENDPOINT+ReportName);
}
//INVOICE API
function fetchSubscriptionList(){
  return Api.get(SUBSCRIPTION_DETAIL);
}
function fetchInvoiceList(ID){
  const queryParams = `/${ID}`;
  return Api.get(`${INVOICES_LIST}${queryParams}`)
}
function fetchInvoiceDetails(ID){
  const queryParams = `/${ID}`;
  return Api.get(`${INVOICE_DETAIL}${queryParams}`)
}
//SANCTION LIST API FUNCTION
function fetchSanctionList(searchQuery) {
  const queryParams = `?search=${searchQuery || ""}`;
  return Api.get(`${SANCTION_LIST_DATA}${queryParams}`);
}
function fetchSanctionDetails(fincenUID) {
  return Api.get(SANCTION_LIST_DATA+fincenUID);
}
//FINCEN THROUGH GLOBAL SEARCH API FUNCTION
function finCenGlobalSearch(
  type,
  country,
  query,
  andQuery,
  orQuery,
  fromDate,
  toDate,
  level
) {
  let queryParams = `?type=${type || ""}&country=${country || ""}&search=${
    query || ""
  }&and_search_terms=${andQuery || ""}&`;
  queryParams += `or_search_terms=${orQuery || ""}&date_after=${
    toDate || ""
  }&date_before=${fromDate || ""}&level=${level || ""}`;

  return Api.get(`${FINCEN_GLOBAL_SEARCH_ENDPOINT}${queryParams}`);
}
//FINCEN THROUGH GLOBAL SEARCH API FUNCTION
function sanctionGlobalSearch(
  type,
  country,
  query,
  andQuery,
  orQuery,
  fromDate,
  toDate,
  level
) {
  let queryParams = `?type=${type || ""}&country=${country || ""}&search=${
    query || ""
  }&and_search_terms=${andQuery || ""}&`;
  queryParams += `or_search_terms=${orQuery || ""}&date_after=${
    toDate || ""
  }&date_before=${fromDate || ""}&level=${level || ""}`;

  return Api.get(`${SANCTION_GLOBAL_SEARCH_ENDPOINT}${queryParams}`);
}
function fetchDataAssets() {
  return Api.get(DATA_ASSETS_DATA);
}
const AccountApi = {
  login,
  register,
  fetchAccountDetails,
  fetchTickerData,
  fetchSubscriptionPlans,
  fetchTeamMemberList,
  addTeamMember,
  removeTeamMember,
  addTeamMemberSubscription,
  removeTeamMemberSubscription,
  forgotPassword,
  updateAccountDetails,
  fetchUserProfileImage,
  updateUserProfileImage,
  buyPlan,
  updatePlan,
  removePlan,
  refreshToken,
  fetchConfirmEmail,
  loginSSO,
  changePasswordSSO,
  resetForgotPassword,
  fetchVerifiedEmail,
  fetchGlobalSearchResult,
  saveGlobalSearchInput,
  fetchGlobalSearchInput,
  deleteSavedSeacrhInput,
  editSavedSeacrhInput,
  fetchInsightData,
  sendMail,
  fetchNewsByID,
  newsTheme,
  fetchNewsBySearch,
  fetchRegulationTicker,
  fetchRegulationDetail,
  fetchFinCenList,
  fetchFinCenDetails,
  fetchReportList,
  fetchReportDetail,
  fetchSubscriptionList,
  fetchInvoiceList,
  fetchInvoiceDetails,
  fetchSanctionList,
  fetchSanctionDetails,
  finCenGlobalSearch,
  sanctionGlobalSearch,
  fetchDataAssets,
  fetchRecentRegulationsList,
  fetchLatestInsight,
  fetchInsightDetail
};

export default AccountApi;
