import React from "react";
import { Bar } from "react-chartjs-2";

export default class EcoBarGraph extends React.Component {
  componentDidMount = () => {};

  drawGraph = (graphData, graphLabel) => {
    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 170);
      gradient.addColorStop(0, "#bdbbfb");
      gradient.addColorStop(1, "#807df8");

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 170);
      gradientFill.addColorStop(0, "#e6e8fa");
      gradientFill.addColorStop(1, "#ffffff");

      return {
        type:"bar",
        labels: graphLabel,
        datasets: [
          {
            label: "",
            fill: true,
            lineTension: 0.3,
            backgroundColor: gradientFill,
            borderColor: gradient,
            borderWidth: 2,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: gradient,
            pointBackgroundColor: gradient,
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: gradient,
            pointHoverBorderColor: gradient,
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointStyle: "circle",
            pointHitRadius: 10,
            data: graphData,
            barThickness: 40,
          },
        ],
      };
    };
    const options = {
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#000",
        bodyFontColor: "#000",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      //lineTension: 5,
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "rgba(0,0,0,0.5)",
              fontStyle: "normal",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
              display: false,
            },
            gridLines: {
              drawTicks: false,
              display: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "rgba(0,0,0,0.5)",
              fontStyle: "normal",
              //fontFamily: 'Montserrat',
              display: false,
            },
          },
        ],
      },
    };
    return <Bar data={data} options={options} width={100} height={180} responsiveness={true} />;
  };

  render() {
    const { className } = this.props;
    let { graphLabel, graphData } = this.props;
    graphData = graphData instanceof Array ? graphData : [];
    graphLabel = graphLabel instanceof Array ? graphLabel : [];

    return (
      <div className={className}>{this.drawGraph(graphData, graphLabel)}</div>
    );
  }
}
