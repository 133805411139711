import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";
import ProtectedRoute from './ProtectedRoute';
import NotFound from "pages/NotFound";
const {
  DASHBOARD_PAGE_PATH,
  DEVELOPERS_GUIDE_PAGE_PATH,
  EVENTS_PAGE_PATH,
  EVENT_DETAIL_PAGE_PATH,
  COUNTRY_PAGE_PATH,
  COUNTRY_DETAIL_PAGE_PATH,
  GLOBAL_SEARCH_PAGE_PATH,
  GLOBAL_HISTORY_PAGE_PATH,
  GLOBAL_SAVED_INPUT_PAGE_PATH,
  RESOURCES_REGULATIONS_PAGE_PATH,
  RESOURCE_REGULATIONS_DETAIL_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  PROFILE_PAGE_PATH,
  PIN_NOTE_PAGE_PATH,
  LOADER_URL,
  SIGN_IN_PAGE_PATH,
  WELCOME_PAGE_PATH,
  RESET_PASS_PAGE_PATH,
  RESET_PASS_SUCCESS_PAGE_PATH,
  INSIGHTS_PAGE_PATH,
  NEWS_PAGE_PATH,
  FINCIN_DETAIL_PAGE_PATH,
  REPORTS_DETAIL_PAGE_PATH,
  SANCTION_DETAIL_PAGE_PATH,
  not_found,
  DATA_ASSETS,
  INSIGHT_LIST,
  INSIGHT_DETAIL_PAGE_PATH,
} = SITE_PATHS;

const DevelopersGuidePage = asyncComponent(() =>
  import("pages/DevelopersGuide").then((module) => module.default)
);

const DashboardPage = asyncComponent(() =>
  import("pages/DashboardPage").then((module) => module.default)
);
const GlobalHistoryPage = asyncComponent(() =>
  import("pages/GlobalHistoryPage").then((module) => module.default)
);

const SavedGlobalInputPage = asyncComponent(() =>
  import("pages/SavedGlobalInputPage").then((module) => module.default)
);
const EventsPage = asyncComponent(() =>
  import("pages/EventsPage").then((module) => module.default)
);

const EventDetailPage = asyncComponent(() =>
  import("pages/EventDetailPage").then((module) => module.default)
);

const CountriesPage = asyncComponent(() =>
  import("pages/CountriesPage").then((module) => module.default)
);

const CountryDetailPage = asyncComponent(() =>
  import("pages/CountryDetailPage").then((module) => module.default)
);

const GlobalSearchPage = asyncComponent(() =>
  import("pages/GlobalSearchPage").then((module) => module.default)
);

const ResourcesPage = asyncComponent(() =>
  import("pages/ResourcesPage").then((module) => module.default)
);

const ResourcesDetailPage = asyncComponent(() =>
  import("pages/ResourcesDetailPage").then((module) => module.default)
);

const ProfileEditPage = asyncComponent(() =>
  import("pages/ProfileEditPage").then((module) => module.default)
);

const ProfilePage = asyncComponent(() =>
  import("pages/ProfilePage").then((module) => module.default)
);

const PinNotePage = asyncComponent(() =>
  import("pages/PinNotePage").then((module) => module.default)
);

const VerifyPage = asyncComponent(() =>
  import("pages/VerifyPage").then((module) => module.default)
);

const WelcomePage = asyncComponent(() =>
  import("pages/WelcomePage").then((module) => module.default)
);
const InsightsPage = asyncComponent(() =>
  import("pages/InsightsPage").then((module) => module.default)
);
const NewsPage = asyncComponent(() =>
  import("pages/NewsPage").then(module => module.default)
);
const FincenDetailPage = asyncComponent(() =>
  import("pages/FinCenDetailPage").then(module => module.default)
);
const ReportsDetailPage = asyncComponent(() =>
  import("pages/ReportsDetailPage").then(module => module.default)
);
const SanctionDetailPage = asyncComponent(() =>
  import("pages/SanctionDetailPage").then(module => module.default)
);
const DataAssetsPage = asyncComponent(() =>
  import("pages/Data Assets").then(module => module.default)
);
const InsightListPage = asyncComponent(() =>
  import("pages/InsightList").then(module => module.default)
);
const InsightDetailPage = asyncComponent(() =>
  import("pages/InsightDetailPage").then(module => module.default)
);
const MainLayoutRoutes = () => (
  <Switch>
    <Route
      exact
      path={DEVELOPERS_GUIDE_PAGE_PATH}
      component={DevelopersGuidePage}
    />
    <ProtectedRoute exact path={DASHBOARD_PAGE_PATH} component={DashboardPage} />
    <ProtectedRoute
      exact
      path={`${EVENT_DETAIL_PAGE_PATH}/:eventUID`}
      component={EventDetailPage}
    />

    <ProtectedRoute exact path={`${COUNTRY_PAGE_PATH}`} component={CountriesPage} />
    <ProtectedRoute path={INSIGHTS_PAGE_PATH} component={InsightsPage} />
    <ProtectedRoute
      exact
      path={`${COUNTRY_DETAIL_PAGE_PATH}/:countryUID`}
      component={CountryDetailPage}
    />

    <ProtectedRoute
      exact
      path={`${GLOBAL_SEARCH_PAGE_PATH}`}
      component={GlobalSearchPage}
    />
     <ProtectedRoute
      exact
      path={`${GLOBAL_HISTORY_PAGE_PATH}`}
      component={GlobalHistoryPage}
    />
    <ProtectedRoute
      exact
      path={`${GLOBAL_SAVED_INPUT_PAGE_PATH}`}
      component={SavedGlobalInputPage}
    />
    <ProtectedRoute
      exact
      path={`${RESOURCES_REGULATIONS_PAGE_PATH}`}
      component={ResourcesPage}
    />
    <ProtectedRoute
      exact
      path={`${RESOURCE_REGULATIONS_DETAIL_PAGE_PATH}/:regulationID`}
      component={ResourcesDetailPage}
    />
    <ProtectedRoute
      exact
      path={`${PROFILE_EDIT_PAGE_PATH}`}
      component={ProfileEditPage}
    />
    <ProtectedRoute exact path={`${PROFILE_PAGE_PATH}`} component={ProfilePage} />
    <ProtectedRoute exact path={NEWS_PAGE_PATH} component={NewsPage} />
    <ProtectedRoute
      exact
      path={`${FINCIN_DETAIL_PAGE_PATH}/:fincenUID`}
      component={FincenDetailPage}
    />
    <ProtectedRoute
      exact
      path={`${REPORTS_DETAIL_PAGE_PATH}/:ReportName`}
      component={ReportsDetailPage}
    />
     <ProtectedRoute
      exact
      path={`${SANCTION_DETAIL_PAGE_PATH}/:sanctionUID`}
      component={SanctionDetailPage}
    />
       <ProtectedRoute
      exact
      path={`${DATA_ASSETS}`}
      component={DataAssetsPage}
    />
      <ProtectedRoute
      exact
      path={`${INSIGHT_LIST}/:ResourceName`}
      component={InsightListPage}
    />
      <ProtectedRoute
      exact
      path={`${INSIGHT_DETAIL_PAGE_PATH}/:SpecificResourceName`}
      component={InsightDetailPage}
    />
    <Route
      exact
      path="/"
      render={() => <Redirect to={SIGN_IN_PAGE_PATH} />}
    />

    <Route
      exact
      path="/welcome"
      render={() => <Redirect to={WELCOME_PAGE_PATH} />}
    />
    <Route
      exact
      path="/reset-password-request"
      render={() => <Redirect to={RESET_PASS_PAGE_PATH} />}
    />
    <Route
      exact
      path="/reset-password-request"
      render={() => <Redirect to={RESET_PASS_SUCCESS_PAGE_PATH} />}
    />

    <Route
      exact
      path={`${PIN_NOTE_PAGE_PATH}`}
      component={PinNotePage} />

    <Route
      exact
      path={`${LOADER_URL}`}
      component={VerifyPage} />
      
    {/* URL NOT FOUND */}
    <ProtectedRoute exact path={`${not_found}`} component={NotFound}/>
    <Redirect from="*" to="/page-not-found"/>

  </Switch>
);

export default MainLayoutRoutes;
