import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";
import ShareEvents from "pages/ShareEvents";
const { DOCUMENT_VIEWER_PAGE_PATH ,SHARE_EVENTS} = SITE_PATHS;

const DocumentViewerPage = asyncComponent(() =>
  import("pages/DocumentViewerPage").then((module) => module.default)
);
// const ShareEventsPage= asyncComponent(()=>{
//   import("pages/ShareEvents").then((module)=>module.default)
// })

const BlankLayoutRoutes = () => (
  <Switch>
    <Route
      exact
      path={`${DOCUMENT_VIEWER_PAGE_PATH}/:documentId`}
      component={DocumentViewerPage}
    />

    <Route
      exact
      path={`${DOCUMENT_VIEWER_PAGE_PATH}`}
      component={DocumentViewerPage}
    />
     <Route
      path={`${SHARE_EVENTS}`}
      component={ShareEvents}
    />
  </Switch>
);

export default BlankLayoutRoutes;
