export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_PROFILE_PICTURE = "SET_USER_PROFILE_PICTURE";
export const SET_USER_PROFILE_CONSTANT = "SET_USER_PROFILE_CONSTANT";
export const DESTROY_SESSION = "DESTROY_SESSION";

export function setUserData(data) {
  return { type: SET_USER_DATA, data };
}

export function setUserProfilePicture(data) {
  return { type: SET_USER_PROFILE_PICTURE, data };
}

export function setUserProfileConstant(data) {
  return { type: SET_USER_PROFILE_CONSTANT, data };
}

export function logout() {
  return { type: DESTROY_SESSION };
}

const UserActions = {
  setUserData,
  setUserProfilePicture,
  setUserProfileConstant,
  logout
};

export default UserActions;
