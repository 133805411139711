import iconApartment from "assets/images/icons/icon-apartment.svg";
import iconBriefcase from "assets/images/icons/icon-briefcase.svg";
import iconDashboard from "assets/images/icons/icon-dashboard.svg";
import iconFile from "assets/images/icons/icon-file.svg";
import iconGavel from "assets/images/icons/icon-gavel.svg";
import iconInfo from "assets/images/icons/icon-info.svg";
import iconNotifications from "assets/images/icons/icon-notifications.svg";
import iconSearch from "assets/images/icons/icon-search.svg";
import iconServer from "assets/images/icons/icon-server.svg";
import iconStarBox from "assets/images/icons/icon-star-box.svg";
import iconTriangle from "assets/images/icons/icon-triangle.svg";
import iconUserSettings from "assets/images/icons/icon-user-settings.svg";
import iconUser from "assets/images/icons/icon-user.svg";
import iconWorld from "assets/images/icons/icon-world.svg";
import iconPrison from "assets/images/icons/icon-prison.svg";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";
import iconEventsRound from "assets/images/icons/events-white-round.png";
import resources from "assets/images/icons/resources.svg";
import iconNewsPaper from "assets/images/icons/newspaper.svg";
import dataassets from "assets/images/icons/Vector.svg"
const SVG_IMAGES = {
  apartment: iconApartment,
  briefcase: iconBriefcase,
  dashboard: iconDashboard,
  file: iconFile,
  gavel: iconGavel,
  info: iconInfo,
  notifications: iconNotifications,
  search: iconSearch,
  server: iconServer,
  "star-box": iconStarBox,
  triangle: iconTriangle,
  "user-settings": iconUserSettings,
  user: iconUser,
  world: iconWorld,
  prison: iconPrison,
  "admin-profile": iconAdminProfile,
  "events-round": iconEventsRound,
  resources:resources,
  newsPaper: iconNewsPaper,
  dataAsset:dataassets,
};

export default SVG_IMAGES;
