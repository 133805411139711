import axios from "axios";
import Authorization from "services/AuthorizationService";
import { API } from "config";

const axiosInstance = axios.create({ baseURL: API.BASE_URL });

function setToken(token) {
  axiosInstance.defaults.headers.common["Authorization"] = "bearer " + token;
}

axiosInstance.interceptors.request.use(async (req) => {
  const token = Authorization.getAccessToken();
  setToken(token);
  req.headers.Authorization = "Bearer " + token;
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const status = err.response.status;

    if (status === 401) {
      Authorization.logout();
    }

    return err;
  }
);

function getDocumentLinkFromId(id) {
  const queryParam = `?resourcesuuid=${id}`;
  
  return axiosInstance.get(`v1/regulation/pdf_link${queryParam}`);
}

const DocumentApi = {
  getDocumentLinkFromId,
};

export default DocumentApi;
