import React from "react";
import { Link } from "react-router-dom";

const DEFAULT_TEXT_COLOR = "blue";
const DEFAULT_BORDER_COLOR = "blue";
const DEFAULT_BG_COLOR = "white";

function Button(props) {
  const textColor = props.textColor || DEFAULT_TEXT_COLOR;
  const borderColor = props.borderColor || DEFAULT_BORDER_COLOR;
  const bgColor = props.bgColor || DEFAULT_BG_COLOR;

  const buttonName = props.text || "Button";
  const type = props.type || "button";

  let className = "input-button uncopyable";
  className += textColor ? " text-color-" + textColor : "";
  className += borderColor ? " border-color-" + borderColor : "";
  className += bgColor && !props.bgGradient ? " bg-color-" + bgColor : "";
  className += props.bgGradient ? " gradient-color-" + props.bgGradient : "";
  className += props.borderRound ? " border-round" : "";
  className += props.className ? ` ${props.className}` : "";
  className += props.disabled ? ` disabled` : "";
  className += props.fade ? ` fade` : "";
  className += props.fullWidth ? ` w-100` : "";

  const DefaultOnClick = () => {
    if (props.disabled) return;
    console.error(`No Onclick implemented for button named: ${buttonName}`);
  };

  const onClickFunction =
    props.onClick instanceof Function ? props.onClick : DefaultOnClick;

  const ButtonComponent = () => (
    <button
      type={type}
      id={props.id || ""}
      className={className}
      onClick={(e) => {
        if (type === "submit") {
          e.preventDefault();
        }

        if (!props.disabled) {
          onClickFunction(e);
        }
      }}
    >
      {props.children}
      {!props.children && buttonName}
    </button>
  );

  return props.to ? (
    <Link
      className={props.linkClassName ? props.linkClassName : ""}
      to={props.to}
      rel={props.openInNewTab ? "noopener noreferrer" : ""}
      target={props.openInNewTab ? "_blank" : ""}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();
        }
      }}
    >
      <ButtonComponent />
    </Link>
  ) : (
    <ButtonComponent />
  );
}


export default Button;