import axios from "axios";
import Authorization from "services/AuthorizationService";
import { API } from "config";
import { userActions } from "services/Redux/Actions";
import Cookies from "js-cookie";
// import { STATE } from "config";
// import History from "services/History";

const axiosInstance = axios.create({ baseURL: API.BASE_URL });

function setToken(token) {
  if (token)
    axiosInstance.defaults.headers.common["Authorization"] = "bearer " + token;
}

axiosInstance.interceptors.request.use(async req => {
  const token = Authorization.getAccessToken();
  setToken(token);
  if (window.sessionStorage.getItem("code")) {
    req.headers.code = window.sessionStorage.getItem("code");
  }
  if (window.sessionStorage.getItem("key")) {
    req.headers.key = window.sessionStorage.getItem("key");
  }
  if (token) req.headers.Authorization = "Bearer " + token;
  return req;
});

let res = null;

axiosInstance.interceptors.response.use(
  respose => {
    res = respose;
    return res;
  },
  err => {
    const status = err.response.status;

    if (status === 401) {
      Authorization.logout();
    }
    // return err;
    res = err.response;
    return res;
  }
);

function get(endpoint, data = {}, responseType) {
  return axiosInstance
    .get(endpoint, { data, responseType })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

function post(endpoint, data = {}, headers) {
  return axiosInstance
    .post(endpoint, data, { headers })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

function put(endpoint, data) {
  return axiosInstance
    .put(endpoint, data)
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

function _delete(endpoint, data) {
  return axiosInstance
    .delete(endpoint, data)
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

function patch(endpoint, data) {
  return axiosInstance
    .patch(endpoint, data)
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
}

const ApiWrapper = {
  get,
  post,
  put,
  delete: _delete,
  patch,
};

export default ApiWrapper;
