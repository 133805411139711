import React from "react";
import moment from "moment";
import { Button } from "components/Input";
import {
  getCriticalityIconFromScore,
  getCriticalityTextFromScore,
} from "utils";

function EventCardB(props) {
  const eventDate = moment(props.event_date).format("DD MMM yyyy");
  const eventYear =props.end_year
  const criticalityIcon = getCriticalityIconFromScore(props.event_criticality);
  const criticalityText = getCriticalityTextFromScore(props.event_criticality);

  return (
    <div className="row event-card-b" onClick={props.onClick}>
      <div className="col-md-10"> <h3
        className={`event-card-b__event-name${props.eventNameClassName ? " " + props.eventNameClassName : ""
          }`}
        onClick={props.onEventNameClick}
      >
        {props.event_name}
      </h3>

        <div className="mt-2">
          <span>{!!props.event_date ? eventDate: eventYear }</span>
          <span className="blue-dot mx-4" />
          <span data-toggle="tooltip" title="Event Id">{props.event_uuid}</span>
          <span className="blue-dot mx-4" />
          {props.country_flag ? <><span className="event-card-b__flag-container">
            <img
              className="event-card-b__flag-image"
              src={ `data:image/png;base64,${props.country_flag}`}
              alt={props.country_name || "flag"}
              data-toggle="tooltip"
              title={props.country_name}
            />

          </span>
            <span className="blue-dot mx-4" /></> : ""}
          <span>Relevance: {props.relevance_score}%</span>
        {  props.criticality && <> <span className="blue-dot mx-4" />
          <span>
            Criticality:{" "}
            <img
              className="event-card-b__criticality-image ml-2"
              src={criticalityIcon}
              alt={criticalityText}
              data-toggle="tooltip"
              title={criticalityText}
            />
          </span></>}
        </div>

        <div className="mt-2 event-card-b__methods" data-toggle="tooltip" title="Reason">
          {(props.methods || [])
            .map((method, i) => {
              return method.method;
            })
            .join(" , ")}
        </div>

        <div className="mt-3">
          {(props.tags || []).map((tag, i) => {
            return (
              <span key={i} className="tag" data-toggle="tooltip" title="tag">
                {tag}
              </span>
            );
          })}
        </div>

      </div>
      <div className="col-md-2 text-right d-flex align-items-end flex-column">
        {props.EventFlag && <div><img src={props.EventFlag} className="w-25px mb-1 d-block ml-auto" data-toggle="tooltip" title={props.flagText} alt={props.flagText}></img></div>}
        <div className="mt-auto">
        <Button
          text="Read More"
          className="event-card-b__read-more-button"
          onClick={props.handleReadMore}
        />
        </div>
      </div>
    </div>
  );
}

export default EventCardB;
