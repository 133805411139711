import React from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";

function Select(props) {
  let className = `input-select${
    props.inputClassName ? " " + props.inputClassName : ""
  }`;

  const selectProps = {
    ...props,
    className,
    classNamePrefix: "input-select",
  };

  return (
    <div
      className={`input-select__container${
        props.className ? " " + props.className : ""
      }${props.fullWidth ? " input-select__container--full-width" : ""}`}
    >
      {props.canAddCustom ? (
        <Creatable
          {...selectProps}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 5000 }),
            menuPortal: (base) => ({ ...base, zIndex: 5000 }),
          }}
        />
      ) : (
        <ReactSelect
          {...selectProps}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 5000 }),
            menuPortal: (base) => ({ ...base, zIndex: 5000 }),
          }}
        />
      )}
    </div>
  );
}

export default Select;
