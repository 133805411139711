import React from 'react'
import { SiteNavigationPanel, SiteFooter } from "components/Layout";
import ShareEventContainer from './ShareEventContainer';
function ShareEvents() {
  return (<>
    <div className="main-layout">
      <SiteNavigationPanel />
           <ShareEventContainer/>
      <SiteFooter />
    </div>
    </>
  );
}

export default ShareEvents