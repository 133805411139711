import Loader from "react-loader-spinner";

function SiteLoader(props) {
  return (
    <Loader
      className={props.className}
      type="Bars"
      color="#090763"
      height={28}
      width={28}
    />
  );
}

function FullPageLoader(props) {
  return (
    <div className="full-page-loader">
      <Loader
        className={props.className}
        type="Bars"
        color="#090763"
        height={36}
        width={36}
      />
    </div>
  );
}

export default SiteLoader;
export { FullPageLoader };
