// ------------------| ACCOUNTS ENDPOINTS |------------------

// http://aml-en-clientapi-dev.eba-tb5txjce.us-west-2.elasticbeanstalk.com/api/v1/

export const SSO_CODE_LOGIN_ENDPOINT = "v2/cognito/code_login";
export const SSO_RGEISTER_ENDPOINT = "v2/cognito/register";
export const SSO_LOGIN_ENDPOINT = "v2/cognito/login";
export const SSO_CHANGE_PASSWORD_ENDPOINT = "v2/user/change_password";

export const LOGIN_ENDPOINT = "v1/login";
export const REGISTER_ENDPOINT = "v1/user/register";
export const USER_DATA_ENDPOINT = "v1/user";
export const UPDATE_USER_DATA_ENDPOINT = "v1/user/update";
export const USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";
export const UPDATE_USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";

export const USER_TICKER_DATA_ENDPOINT = "v1/user/teams/ticker";
export const SUBSCRIPTION_PLANS_LIST_ENDPOINT = "v1/subscription/plan";
export const TEAM_MEMBER_LIST_ENDPOINT = "v1/user/teams/member_list";
export const ADD_TEAM_MEMBER_ENDPOINT = "v1/user/teams/add_member";
export const REMOVE_TEAM_MEMBER_ENDPOINT = "v1/user/teams/remove_member";
export const UPDATE_TEAM_MEMBER_SUBSCRIPTION_ENDPOINT =
  "v1/user/teams/update_subscriptions";
export const FORGOT_PASSWORD_ENDPOINT = "v2/user/forgot_password";
export const RESET_FORGOT_PASSWORD_ENDPOINT = "v2/user/reset_forgot_password";
export const BUY_PLAN_ENDPOINT = "v1/subscription/";
export const UPDATE_PLAN_ENDPOINT = "v1/subscription/";
export const REMOVE_PLAN_ENDPOINT = "v1/subscription/";

// ------------------| SEARCH ENDPOINTS |------------------
export const SEARCH_ENDPOINT = "v1/core/global_search/";
export const SEARCH_RESULT_ENDPOINT =
  "v1/core/global_search/search_history?status=&result_set=10";
export const SEARCH_SAVED_INPUTS_ENDPOINT =
  "v1/core/global_search/saved_searches";
export const SEARCH_SAVED_INPUTS_LIST_ENDPOINT =
  "v1/core/global_search/saved_searches";
export const DELETE_SAVED_INPUTS_LIST_ENDPOINT =
  "v1/core/global_search/saved_searches_single";
export const EDIT_SAVED_INPUTS_LIST_ENDPOINT =
  "v1/core/global_search/saved_searches_single";
export const FINCEN_GLOBAL_SEARCH_ENDPOINT =
  "v1/fincen/global_search/";
export const SANCTION_GLOBAL_SEARCH_ENDPOINT =
  "v1/Sanction/sanctionall/";
// ------------------| RESOURCES ENDPOINTS |------------------
// export const RESOURCES_ENDPOINT = "core/resources/resources";
export const RESOURCES_ENDPOINT = "v1/regulation";
export const RECENT_REGULATION="v1/regulation/recent_regulations";

// ------------------| COUNTRIES ENDPOINTS |------------------
// export const COUNTRIES_LIST_ENDPOINT = "countries/country/list";
export const COUNTRIES_LIST_ENDPOINT = "v1/core/countries/list";
export const AUTHORISED_COUNTRIES_LIST_ENDPOINT =
  "v1/core/countries/list_protected";
//to be appended by "/country-id"
// export const COUNTRY_DETAILS_ENDPOINT = "countries/country/profile";
export const COUNTRY_DETAILS_ENDPOINT = "v1/core/countries/profile";

export const COUNTRY_INDICE_ENDPOINT = `v1/core/countries/global_indices`;
export const COUNTRY_REGULATOR_ENDPOINT = `v1/core/countries/regulators`;

//----> DISCUSSION
// export const COUNTRY_AML_LAWS_ENDPOINT = `core/resources/all_resources`;
export const COUNTRY_AML_LAWS_ENDPOINT = `v1/regulation`;

export const COUNTRY_ECONOMIC_DATA_ENDPOINT = `v1/core/countries/economicdata`;

// ------------------| EVENTS ENDPOINTS |------------------
// export const EVENTS_LIST_ENDPOINT = "core/events/events_list";
export const EVENTS_LIST_ENDPOINT = "v1/core/events";

// export const RECENT_EVENTS_LIST_ENDPOINT = "home/landing/recentevents";
export const RECENT_EVENTS_LIST_ENDPOINT = "v1/core/events/recent_event";

// export const SEARCH_QUERY_EVENT_ENDPOINT = "events/search";
export const SEARCH_QUERY_EVENT_ENDPOINT = "v1/core/events";

//to be appended by "/event-id"
// export const EVENT_DETAIL_ENDPOINT = "events/event_details";
export const EVENT_DETAIL_ENDPOINT = "v1/core/events";

// ------------------| MAP ENDPOINTS |------------------
// export const EVENTS_MAP_DATA_ENDPOINT = "home/maps/events";
export const EVENTS_MAP_DATA_ENDPOINT = "v1/core/maps/events";

// export const FINES_MAP_DATA_ENDPOINT = "home/maps/fines";
export const FINES_MAP_DATA_ENDPOINT = "v1/core/maps/fines";

// export const IMPRISONMENT_MAP_DATA_ENDPOINT = "home/maps/imprisonment";
export const IMPRISONMENT_MAP_DATA_ENDPOINT = "v1/core/maps/imprisonment";

// export const ENTITY_MAP_DATA_ENDPOINT = "home/maps/entity";
export const ENTITY_MAP_DATA_ENDPOINT = "v1/core/maps/entity";

// export const INDIVIDUAL_MAP_DATA_ENDPOINT = "home/maps/individual";
export const INDIVIDUAL_MAP_DATA_ENDPOINT = "v1/core/maps/individual";

// export const REGULATOR_MAP_DATA_ENDPOINT = "home/maps/regulator";
export const REGULATOR_MAP_DATA_ENDPOINT = "v1/core/maps/regulator";

// export const HOME_TICKER_DATA_ENDPOINT = "home/landing/ticker";
export const HOME_TICKER_DATA_ENDPOINT = "v1/core/landing/ticker";

// ------------------| GRAPH ENDPOINTS |------------------

// export const REASON_COUNT_GRAPH_DATA_ENDPOINT =
//   "home/graphs/reason-category/count";
export const REASON_COUNT_GRAPH_DATA_ENDPOINT =
  "v1/core/graphs/reason_category/count";

// export const REASON_SUM_GRAPH_DATA_ENDPOINT = "home/graphs/reason-category/sum";
export const REASON_SUM_GRAPH_DATA_ENDPOINT =
  "v1/core/graphs/reason_category/sum";

// export const INDUSTRY_COUNT_GRAPH_DATA_ENDPOINT = "home/graphs/industry/count";
export const INDUSTRY_COUNT_GRAPH_DATA_ENDPOINT =
  "v1/core/graphs/industry/count";

// export const INDUSTRY_SUM_GRAPH_DATA_ENDPOINT = "home/graphs/industry/sum";
export const INDUSTRY_SUM_GRAPH_DATA_ENDPOINT = "v1/core/graphs/industry/sum";

// ----> DISCUSSION
// export const EVENTS_COUNT_GRAPH_DATA_ENDPOINT = "home/graphs/events/count";
export const EVENTS_COUNT_GRAPH_DATA_ENDPOINT = "v1/core/graphs/events/count";

// export const EVENTS_TOTAL_COUNT_GRAPH_DATA_ENDPOINT =
//   "home/graphs/events/total-count";
export const EVENTS_TOTAL_COUNT_GRAPH_DATA_ENDPOINT =
  "v1/core/graphs/events/total_count";

// export const EVENTS_FINES_GRAPH_DATA_ENDPOINT = "home/graphs/events/fines";
export const EVENTS_FINES_GRAPH_DATA_ENDPOINT =
  "v1/core/graphs/events/total_fines";

// ------------------| PIN NOTE ENDPOINTS |------------------
export const NOTES_LIST_ENDPOINT = "v1/notes";
export const NOTES_TAG_ENDPOINT = "v1/notes/tags";
export const PINNED_ITEM_ENDPOINT = "v1/home/pinned_items";
export const UNPIN_ITEM_ENDPOINT = "v1/home/unpin_item";

// ------------------| REFRESH & CONFIRM RMAIL ENDPOINTS |------------------

export const REFRESH_TOKEN = "v1/refresh";
export const CONFIRM_EMAIL = "v1/user/confirm_email";
export const VERIFIED_EMAIL = "v2/cognito/email_verify";

// ------------------| INSIGHTS ENDPOINTS |------------------
export const INSIGHTS_ENDPOINT = "cms/content?type=";
export const LATEST_INSIGHT="cms/latest_post";
export const INSIGHT_DETAIL="cms/resources_page"
// ------------------|SEND MAIL ENDPOINTS |------------------
export const SEND_MAIL_BASE_URL = "v1/user/send_contact";
// ------------------|NEWS ENDPOINTS |------------------

export const NEWS_THEME_BASE_URL =
  "https://coreapi.dragnetalpha.com/dauiapi/api/ThemeList?projectId=54";

export const NEWS_SEARCH_BASE_URL =
  "https://coreapi.dragnetalpha.com/dauiapi/api/DataAssetsMasterSearch";

// ------------------|REGULATION TICKER ENDPOINTS |------------------
export const REGULATION_TICKER = "v1/regulation/regulation_ticker";
export const REGULATION_DETAIL_ID = "v1/regulation/";

// ------------------|FINCIN ENDPOINTS |------------------
export const FINCIN_LIST_DATA = "v1/fincen/";
export const FINCIN_DETAIL = "v1/fincen/";

// ------------------|INSIGHT REPORTS ENDPOINTS |------------------
export const REPORT_LIST_DATA = "cms/report?report_type=2";
export const REPORT_DETAIL_ENDPOINT = "cms/report_details/";

//....................|Invoice Endpoints|...........................
export const SUBSCRIPTION_DETAIL="v1/subscription/subscription_list";
export const INVOICES_LIST ="v1/subscription/invoice";
export const INVOICE_DETAIL="v1/subscription/invoice_details";

// ------------------|SANCTION ENDPOINTS |------------------
export const SANCTION_LIST_DATA = "v1/Sanction/";
// ------------------| Data Assets ENDPOINTS |------------------
export const DATA_ASSETS_DATA= "cms/data_asset_get"