import React from "react";
import { Doughnut } from "react-chartjs-2";

export default class DoughnutChart extends React.Component {
  _chartRef = React.createRef();
  state = {
    showTooltip: false,
    tooltip: undefined,
    graphLabel: [],
    graphData: [],
  };

  componentDidMount = () => {};

  drawGraph = (graphData, graphLabel) => {
    const data = {
      labels: graphLabel,
      datasets: [
        {
          data: graphData,
          backgroundColor: [        
            "#8000ff",
            "#e6b167",
            "#9ea1d9",
            "#24ccb8",
            "#02c5ff",
            "#ff8300",
            "#f8fa54",
            "#7dd36f",
            "#23BD97",
            "#0f6787",
            "#0E604D",
            "#FB442E",
          ],
          borderWidth: 0,
        },
      ],
      legend: {
        fullWidth: false,
      },
    };
    return (
      <Doughnut
        data={data}
        // width={400}
        // height={400}
        options={{
          maintainAspectRatio: false,
          cutoutPercentage: 60,
          weight: 2,
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
            },
            tooltips: {
              enabled: false,
              mode: "nearest",
              position: "average",
              //custom: this.showTooltip()
            },
          },
        }}
        ref={this._chartRef}
        height={320}
      />
    );
  };

  showTooltip(tooltip) {
    const chart = this._chartRef.current;
    if (!chart) {
      return null;
    }
    if (tooltip.opacity === 0) {
      this.setState({
        showTooltip: false,
        tooltip: undefined,
      });
    } else {
      this.setState({
        showTooltip: true,
        tooltip,
      });
    }
  }

  render() {
    const { className } = this.props;
    let { graphLabel, graphData } = this.props;
    graphData = graphData instanceof Array ? graphData : [];
    graphLabel = graphLabel instanceof Array ? graphLabel : [];

    return (
      <div className={className}>{this.drawGraph(graphData, graphLabel)}</div>
    );
  }
}
