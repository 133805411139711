import { get as lodashGet } from "lodash";
import { AccountApi } from "services/Api";
import { store, userActions } from "services/Redux";
import History from "services/History";
import { SITE_PATHS } from "config";
import cookie from "react-cookies";
import Cookies from "js-cookie";
import { DEFAULT_USER_PROFILE_PICTURE } from "config";
import toaster from "components/Toaster/Toaster";

function getAccessToken() {
  const token1 = Cookies.get("temp_id");
  const storeData = store.getState();

  //const token = lodashGet(storeData, "user.userData.data.access", null);
  window.sessionStorage.setItem("token", token1);
  if (!window.sessionStorage.getItem("token")) {
    logout();
  }
  return token1;
}

function login(email, password , window, enterlogin) {
  return AccountApi.login(email, password )
    .then(res => {
      if (
        res.status === 200 &&
        res.data &&
        res.data.message !== "Password change is required"
      ) {
        sessionStorage.removeItem("code");
        const userData = res.data;

        store.dispatch(userActions.setUserData(userData));
        const token = lodashGet(userData, "data.access", null);
        const refreshToken = lodashGet(userData, "data.refresh", null);
        const imgData = lodashGet(userData, "data.image", null);

        // setTimeout(function () {
        //fetchProfileImage();
        // }, 3000);

        var date = new Date();
        date.setTime(date.getTime() + 3600 * 1000);

        let Rurl = Cookies.get("app_id");
        
        let returnUrl = window.sessionStorage.getItem("returnUrl");
        window.sessionStorage.setItem("showNewsPopup", true);
        if (Rurl || returnUrl){
          Cookies.set("remove_id", refreshToken, {
            expires: date,
            domain: window.location.hostname,
          });
          Cookies.set("temp_id", token, {
            expires: date,
            domain:window.location.hostname,
          });
          Cookies.set("u_id", userData.data.user_id, {
            expires: date,
            domain:window.location.hostname,
          });
          Cookies.remove("app_id", { domain:window.location.hostname });
          window.sessionStorage.removeItem("returnUrl");

          window.location.replace(returnUrl);
        //LOCAL HOST
          // Cookies.set("remove_id", refreshToken, {
          //   expires: date,
          //   domain: "localhost",
          // });
          // Cookies.set("temp_id", token, {
          //   expires: date,
          //   domain: "localhost",
          // });
          // Cookies.set("u_id", userData.data.user_id, {
          //   expires: date,
          //   domain: "localhost",
          // });
          // Cookies.remove("app_id", { domain: "localhost" });
          // window.sessionStorage.removeItem("returnUrl");
          // window.location.replace(returnUrl);
        //EOF LOCALHOST
        } else {
          Cookies.set("remove_id", refreshToken, {
            expires: date,
            domain:window.location.hostname,
          });
          Cookies.set("temp_id", token, {
            expires: date,
            domain:window.location.hostname,
          });
          Cookies.set("u_id", userData.data.user_id, {
            expires: date,
            domain:window.location.hostname,
          });
          //LOCAL HOST
          // Cookies.set("remove_id", refreshToken, {
          //   expires: date,
          //   domain: "localhost",
          // });
          // Cookies.set("temp_id", token, { expires: date, domain: "localhost" });
          // Cookies.set("u_id", userData.data.user_id, {
          //   expires: date,
          //   domain: "localhost",
          // });
          //EOF LOCAL HOST
          window.location.replace("/dashboard")
         
        }
      } else if (
        res.status === 200 &&
        res.data &&
        res.data.message === "Password change is required"
      ) {
       History.push({
          pathname: SITE_PATHS.CHANGE_PASSWORD,
          state: { userData: res.data && res.data.data },
        });
        window.removeEventListener("keyup", enterlogin )
      } else {
        if (
          res.data.message ==
          "Awaiting email verification. Please verify your email."
        ) {
          sessionStorage.setItem("email", email);
          window.location.replace("https://app.amlpenalties.com/welcome");
        } else {
          toaster.show({ message: res.data.message, intent: "danger" });
          History.push({
            pathname: SITE_PATHS.SIGN_IN_PAGE_PATH
          });
          
        }
      }
    })
    .catch(err => {
      logout();
    });
}

function fetchProfileImage() {
  // if (img !== "") {
  //   store.dispatch(userActions.setUserProfilePicture(img));
  // } else {
  //
  //   store.dispatch(userActions.setUserProfilePicture(DEFAULT_USER_PROFILE_PICTURE));
  // }

  AccountApi.fetchUserProfileImage()
    //.then(async(res) => {
    .then(res => {
      if (res.status === 200) {
        let imageLocalUrl = lodashGet(res, "data.data.download_link", null);
        window.sessionStorage.setItem("profile", imageLocalUrl);
        store.dispatch(userActions.setUserProfilePicture(imageLocalUrl));
      } else {
        store.dispatch(
          userActions.setUserProfilePicture(DEFAULT_USER_PROFILE_PICTURE)
        );
      }
    })
    .catch(err => {
      store.dispatch(
        userActions.setUserProfilePicture(DEFAULT_USER_PROFILE_PICTURE)
      );
    });
}

function logout() {
  Cookies.remove("app_id", { domain:window.location.hostname});
  Cookies.remove("remove_id", { domain:window.location.hostname });
  Cookies.remove("temp_id", { domain:window.location.hostname });
  Cookies.remove("u_id", { domain:window.location.hostname });
  // Cookies.remove("app_id", { domain: "localhost" });
  // Cookies.remove("remove_id", { domain: "localhost" });
  // Cookies.remove("temp_id", { domain: "localhost" });
  // Cookies.remove("u_id", { domain: "localhost" });

  sessionStorage.removeItem("token");
  //  store.dispatch(userActions.logout(null));
  store.dispatch(userActions.setUserData(null));
  //toaster.show({message: "You have logout successfully", intent: "success"});

  History.push(SITE_PATHS.SIGN_IN_PAGE_PATH);
}

export { login, logout, getAccessToken };