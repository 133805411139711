import Api from "./ApiWrapper";
import {
  COUNTRIES_LIST_ENDPOINT,
  AUTHORISED_COUNTRIES_LIST_ENDPOINT,
  COUNTRY_DETAILS_ENDPOINT,
  COUNTRY_INDICE_ENDPOINT,
  COUNTRY_REGULATOR_ENDPOINT,
  COUNTRY_AML_LAWS_ENDPOINT,
  COUNTRY_ECONOMIC_DATA_ENDPOINT,
} from "./ApiEndpoints";

function fetchCountryList(region = "") {
  const queryParams = `?region=${region}`;
  return Api.get(`${COUNTRIES_LIST_ENDPOINT}${queryParams}`);
}

function authorisedFetchCountryList(region = "") {
  const queryParams = `?region=${region}`;
  return Api.get(`${AUTHORISED_COUNTRIES_LIST_ENDPOINT}${queryParams}`);
}

function fetchCountryDetails(countryUID = "") {
  return Api.get(`${COUNTRY_DETAILS_ENDPOINT}/${countryUID}`);
}

function fetchCountryIndiceData(countryCode = "") {
  return Api.get(`${COUNTRY_INDICE_ENDPOINT}/${countryCode}`);
}

function fetchCountryRegulatoryData(countryCode = "") {
  return Api.get(`${COUNTRY_REGULATOR_ENDPOINT}/${countryCode}`);
}

function fetchCountryAmlLaws(countryCode = "") {
  return Api.get(`${COUNTRY_AML_LAWS_ENDPOINT}?country=${countryCode}`);
}

function fetchCountryEconomicData(countryCode = "") {
  return Api.get(`${COUNTRY_ECONOMIC_DATA_ENDPOINT}/${countryCode}`);
}

const CountryApi = {
  fetchCountryList,
  authorisedFetchCountryList,
  fetchCountryDetails,
  fetchCountryIndiceData,
  fetchCountryRegulatoryData,
  fetchCountryAmlLaws,
  fetchCountryEconomicData,
};

export default CountryApi;
