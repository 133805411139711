import Api from "./ApiWrapper";
import { API } from "config";
import {
  RECENT_EVENTS_LIST_ENDPOINT,
  SEARCH_QUERY_EVENT_ENDPOINT,
  EVENTS_LIST_ENDPOINT,
  EVENT_DETAIL_ENDPOINT,
} from "./ApiEndpoints";

function fetchEventsList(
  fromDate,
  toDate,
  country = API.EVENTS_DEFAULT_COUNTRY_CODE,
  reasoning,
  searchQuery
) {
  let queryParams = `/?country=${country || ""}&date_before=${toDate || ""}`;
  queryParams += `&category=${reasoning || ""}&search=${searchQuery || ""}`;
  queryParams += `&date_after=${fromDate || ""}`;

  return Api.get(`${EVENTS_LIST_ENDPOINT}${queryParams}`);
}

function fetchRecentEventsList(limit, country, fromDate, toDate) {
  let queryParams = `?limit=${limit || ""}&country=${country || ""}`;
  queryParams += `&toDate=${toDate || ""}&fromDate=${fromDate || ""}`;
  return Api.get(`${RECENT_EVENTS_LIST_ENDPOINT}${queryParams}`);
}

function fetchEventsFromQuery(
  fromDate,
  toDate,
  country = API.EVENTS_DEFAULT_COUNTRY_CODE,
  reasoning,
  searchQuery
) {
  let queryParams = `?country=${country || ""}&date_before=${toDate || ""}`;
  queryParams += `&category=${reasoning || ""}&search=${searchQuery || ""}`;
  queryParams += `&date_after=${fromDate || ""}`;

  return Api.get(`${SEARCH_QUERY_EVENT_ENDPOINT}${queryParams}`);
}

function fetchEventDetails(eventId) {
  return Api.get(`${EVENT_DETAIL_ENDPOINT}/${eventId}`);
}

const EventsApi = {
  fetchEventsList,
  fetchRecentEventsList,
  fetchEventsFromQuery,
  fetchEventDetails,
};

export default EventsApi;
