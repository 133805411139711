export const COUNTRY_INDICE = [
  {
    name: "BASEL AML Index",
    link: "https://www.baselgovernance.org/basel-aml-index",
    key: "base_aml_index",
    info:
      "The Basel AML Index is an annual ranking assessing country risk regarding money laundering/terrorism financing. It focuses on anti-money laundering and counter terrorist financing (AML/CTF) frameworks and other related factors such as financial/public transparency and judicial strength.",
  },
  {
    name: "Corruption Perception Index",
    link: "https://www.transparency.org/research/cpi/overview",
    key: "corruption_index",
    info:
      'The Corruption Perceptions Index (CPI) is an index published annually by Transparency International since 1995 which ranks countries "by their perceived levels of public sector corruption, as determined by expert assessments and opinion surveys." The CPI generally defines corruption as "the misuse of public power for private benefit".',
  },
  {
    name: "Global Terrorism Index",
    link: "http://visionofhumanity.org/indexes/terrorism-index/",
    key: "terrorism_index",
    info:
      "The Global Terrorism Index (GTI) is a report published annually by the Institute for Economics and Peace (IEP). The index provides a comprehensive summary of the key global trends and patterns in terrorism since 2000. It produces a composite score in order to provide an ordinal ranking of countries on the impact of terrorism.",
  },
  {
    name: "Global Peace Index",
    link: "http://visionofhumanity.org/indexes/global-peace-index/",
    key: "peace_index",
    info:
      "Global Peace Index (GPI) measures the relative position of nations and regions peacefulness. The GPI ranks 163 independent states and territories (99.7 per cent of the world’s population) according to their levels of peacefulness. In the past decade, the GPI has presented trends of increased global violence and less peacefulness.",
  },
  {
    name: "Fragile States Index",
    link: "https://fragilestatesindex.org",
    key: "fragile_state_index",
    info:
      "The Fragile States Index (FSI; formerly the Failed States Index) is an annual report published by the United States think tank the Fund for Peace and the American magazine Foreign Policy since 2005. The list aims to assess states vulnerability to conflict or collapse, ranking all sovereign states with membership in the United Nations where there is enough data available for analysis.",
  },
  {
    name: "Cyber Security Index",
    link:
      "https://www.itu.int/en/ITU-D/Cybersecurity/Pages/global-cybersecurity-index.aspx",
    key: "cyber_index",
    info:
      "The Global Cyber security Index (GCI) is a trusted reference that measures the commitment of countries to cyber security at a global level – to raise awareness of the importance and different dimensions of the issue. As cybersecurity has a broad field of application, cutting across many industries and various sectors.",
  },
];

export const COUNTRY_INDICE_RANK = {
  base_aml_index: {
    high: 'Rank 1 - Highest AML Risk',
    low: 'Rank 149 - Lowest AML Risk',
    rank: { 2023:152,2022:128,2021: 110, 2020: 141, 2019: 125, 2018: 129, 2017: 146, 2016: 149 },
  },
  corruption_index: {
    high: 'Rank 1 - Lowest Perception of Corruption ',
    low: 'Rank 180 - Highest Perception of Corruption',
    rank: {2023:180,2022:180, 2021:180, 2020: 179, 2019: 180, 2018: 180, 2017: 176, 2016: 176 },
  },
  terrorism_index: {
    high: 'Rank 1 - Highest Terrorism Impact on country',
    low: 'Rank 163 - Lowest Terrorism Impact on country',
    rank: { 2022:163,2021:163,2020: 135, 2019: 163, 2018: 163, 2017: 160, 2016: 160 },
  },
  cyber_index: {
    high: ' Rank 1 - Highest Commitment to Cybersecurity',
    low: 'Rank 189 - Lowest Commitment to Cybersecurity',
    rank: { 2020: 182, 2019: 189, 2018: 189, 2017: 188, 2016: 188 },
  },
  fragile_state_index: {
    high: ' Rank 1 - Highest Fragility',
    low: ' Rank 179 - Lowest Fragility',
    rank: { 2022: 179, 2021: 179, 2020: 178, 2019: 178, 2018: 178, 2017: 178, 2016: 178 },
  },
  peace_index: {
    high: 'Rank 1 - Highest Peace',
    low: ' Rank 163 - Lowest Peace',
    rank: { 2022: 163, 2021: 163, 2020: 163, 2019: 163, 2018: 163, 2017: 163, 2016: 163 },
  },
};

export const PENALTY_YEAR_LEGEND = {
  Events: { action: "Year", key: "Events" },
  Penalties: { action: "Year", key: "Amount of Penalties (in Mn USD)" },
};

export const COUNTRY_ECONOMIC_KEY = [
  {
    name: 'GDP (Nominal) (Billion USD)',
    key: 'gdp_nominal',
    info: 'GDP at is the sum of gross value added by all resident producers in the economy plus any product taxes and minus any subsidies not included in the value of the products. It is calculated without making deductions for depreciation of fabricated assets or for depletion and degradation of natural resources. Data are in current U.S. dollars. Dollar figures for GDP are converted from domestic currencies using single year official exchange rates. For a few countries where the official exchange rate does not reflect the rate effectively applied to actual foreign exchange transactions, an alternative conversion factor is used. World Bank maintains this data for its member countries from 1960 to time.'
  },
  {
    name: 'GDP (Nominal) Growth Rate (%)', key: 'gdp_growth_rate',
    info: 'Annual percentage growth rate of gross domestic product (GDP) at market prices based on constant local currency. Aggregates are based on constant 2010 U.S. dollars. GDP is the sum of gross value added by all resident producers in the economy plus any product taxes and minus any subsidies not included in the value of the products. It is calculated without making deductions for depreciation of fabricated assets or for depletion and degradation of natural resources. World Bank maintains this data for its member countries from 1960 to time.'
  },
  { name: 'GDP (PPP) (Billion USD)', key: 'gdp_ppp', info: 'This indicator GDP (PPP - Purchasing Power Parity) provides values for GDP expressed in U.S. dollars, converted by purchasing power parity (PPP) conversion factor.  GDP is the sum of gross value added by all resident producers in the country plus any product taxes and minus any subsidies not included in the value of the products. PPP conversion factor is a spatial price deflator and currency converter that eliminates the effects of the differences in price levels between countries.  From April 2020, “GDP: linked series (current LCU)” [NY.GDP.MKTP.CN.AD] is used as underlying GDP in local currency unit so that it’s in line with time series of PPP conversion factors for GDP, which are extrapolated with linked GDP deflators. World Bank maintains this data for its member countries from 1960 to time.' },
  { name: 'Inflation Rate (%)', key: 'inflation_rate', info: 'Inflation, as measured by the consumer price index, reflects the annual percentage change in the cost to the average consumer of acquiring a basket of goods and services that may be fixed or changed at specified intervals, such as yearly. The Laspeyres formula is generally used to calculate the inflation rate. World Bank maintains global inflation data for its member countries from 1960 to time.' },
  { name: 'Remittance Inflow (Billion USD)', key: 'remittance_inflow', info: 'World Bank released reports on remittance inflow for its member countries on an annual basis. Total remittance inflow number for a country is subject to the availability of remittance inflow information with the World Bank. For some countries, remittances are calculated based on personal transfers and the compensation of employees. Whereas, for some other countries, data is obtained from the respective country’s Central Bank and other relevant official sources. World Bank maintains this data for its member countries from 1960 to time.' },
  { name: 'Remittance Outflow (Billion USD)', key: 'remittance_outflow', info: 'World Bank released reports on remittance outflow for its member countries on an annual basis. Total remittance outflow number for a country is subject to the availability of remittance outflow information with the World Bank. For some countries, remittances are calculated based on personal transfers and the compensation of employees. Whereas, for some other countries, data is obtained from the respective country’s Central Bank and other relevant official sources. World Bank maintains this data for its member countries from 1960 to time.' },
];
