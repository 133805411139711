import React from "react";
import { Pie } from "react-chartjs-2";

export default class PieChart extends React.Component {
  drawGraph = (graphLabel, graphData) => {
    const data = {
      labels: graphLabel,
      datasets: [
        {
          data: graphData,
          backgroundColor: [
            "#007aff",
            "#ff5660",
            "#484edb",
            "#24ccb8",
            "#02c5ff",
            "#ff8300",
            "#f8fa54",
            "#7dd36f",
            "#23BD97",
            "#0f6787",
            "#0E604D",
            "#FB442E",
          ],
          borderWidth: 0,
        },
      ],
      legend: {
        fullWidth: false,
      },
    };
    const options = {
      maintainAspectRatio: false,
      //cutoutPercentage: 80,
      weight: 2,
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
        tooltips: {
          enabled: false,
          mode: "nearest",
          position: "average",
          //custom: this.showTooltip()
        },
      },
    };
    return <Pie data={data} options={options} height={320} />;
  };

  render() {
    const { className } = this.props;
    let { graphLabel, graphData } = this.props;
    graphData = graphData instanceof Array ? graphData : [];
    graphLabel = graphLabel instanceof Array ? graphLabel : [];

    return (
      <div className={className}>{this.drawGraph(graphLabel, graphData)}</div>
    );
  }
}
