import React from "react";
import { Loader } from "components/SiteArt";

function TickerCard(props) {
  const isVariantB = props.variant === "B";
  const cardTitle = (
    <div
      className={`ticker-card__title${
        props.isActive ? " ticker-card__title--active" : ""
      }`}
    >
      {props.title}
    </div>
  );

  const cardValue = (
    <div
      className={`ticker-card__value${
        props.isActive ? " ticker-card__value--active" : ""
      }`}
    >
      {props.valueUpdateInProgress ? <Loader /> : props.value}
    </div>
  );

  return (
    <div
      className={`ticker-card uncopyable${
        props.className ? " " + props.className : ""
      }${props.isActive ? " ticker-card--active" : ""}`}
      onClick={props.onClick}
    >
      <div className="ticker-card__header">
        {isVariantB ? cardValue : cardTitle}
        <div
          className={`ticker-card__icon${
            props.isFontIcon ? " ticker-card__icon--font-icon" : ""
          }${props.isActive ? " ticker-card__icon--active" : ""}`}
        >
          {props.icon}
        </div>
      </div>

      {isVariantB ? cardTitle : cardValue}
    </div>
  );
}

export default TickerCard;
