import moment from "moment";

export function formatDateForApiFormat(date) {
  let formattedDate = null;

  if (date) {
    formattedDate = moment(date).format("yyyy-MM-DD");
  }

  if (date === "Invalid date") {
    formattedDate = null;
  }

  return formattedDate;
}
